import { Link } from "react-router-dom";
import Seo from "../components/seo";
import Header from "../components/header";

const NotFound = () => {
  return (
    <>
      <Seo title="Pagina non trovata" />
      <Header />
      <div role="main" className="main-wrapper mt-3" id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Attenzione! Pagina non trovata</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Link to="/">Torna alla Homepage</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
