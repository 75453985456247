import Icon from "../components/icons";
import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PageContent from "./page-content";

const Accordion = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="d-flex flex-column h-100 mb-4">
      <h2
        className="footer__title"
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <span className="footer__title-label">{title}</span>
        <span className="footer__title-line d-block d-lg-none">&nbsp;</span>
        <Icon
          name={isActive ? "angle-up" : "angle-down"}
          className="d-block d-lg-none"
        />
      </h2>
      <div
        className={classNames(
          "footer__accordion h-100",
          isActive && "footer__accordion--open"
        )}
      >
        {children}
      </div>
    </div>
  );
};

const Footer = ({ data }) => {
  // console.log("footer", data);
  return (
    <footer role="contentinfo" className="footer">
      <div className="footer__top d-print-none mb-4">
        <div className="container-md">
          <div className="row">
            {data?.map((item, index) => (
              <div key={index} className="col-sm-12 col-lg-3">
                <Accordion title={item.titolo}>
                  <div className="footer__text has-btn">
                    <PageContent data={item} />
                    {index === 0 && (
                      <div className="text-center mt-2">
                        <Icon name="asterisco" className="asterisco" />
                      </div>
                    )}
                  </div>
                  {item.link && (
                    <Link
                      className="footer__arrow mt-auto"
                      title={item.link.titolo}
                      to={item.link.slug}
                    >
                      <Icon name="angle-right" />
                      {item.link.titolo}
                    </Link>
                  )}
                </Accordion>
              </div>
            ))}

            <div className="col-sm-12 col-lg-3">
              <Accordion title="Newsletter">
                <div className="footer__text">
                  <div className="footer__cta">
                    <Link
                      to="/iscriviti-alla-newsletter/"
                      className="btn btn--link"
                    >
                      Iscriviti
                    </Link>
                  </div>
                  <br />
                </div>

                <div className="footer__box">
                  <h2 className="footer__title footer__title--social">
                    Seguici
                  </h2>
                  <div>
                    <a
                      className="footer__social"
                      title="Facebook"
                      href="https://www.facebook.com/festivalscienza"
                    >
                      <Icon name="facebook" />
                    </a>
                    <a
                      className="footer__social"
                      title="Twitter"
                      href="https://twitter.com/fdellascienza?lang=it"
                    >
                      <Icon name="x" />
                    </a>
                    <a
                      className="footer__social"
                      title="YouTube"
                      href="https://www.youtube.com/user/FestivalScienza"
                    >
                      <Icon name="youtube" />
                    </a>
                    <a
                      className="footer__social"
                      title="Instagram"
                      href="https://www.instagram.com/festivalscienza/"
                    >
                      <Icon name="instagram" />
                    </a>
                    <a
                      className="footer__social"
                      title="Flickr"
                      href="https://www.flickr.com/photos/festivaldellascienza/"
                    >
                      <Icon name="flickr" />
                    </a>
                  </div>
                  <div className="clear">&nbsp;</div>
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom d-print-none">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <span className="copyright">
                © Associazione Festival della Scienza
              </span>{" "}
              -
              <span className="px-1">
                <Link
                  className="footer__link"
                  title="Cookie policy"
                  to="/cookie-policy"
                >
                  Cookie policy
                </Link>
              </span>{" "}
              -
              <span className="px-1">
                <Link
                  className="footer__link"
                  title="Informativa sul trattamento dei dati personali"
                  to="/informativa-sul-trattamento-dei-dati-personali"
                >
                  Informativa sul trattamento dei dati personali
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
