import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToTop = () => {
  const ref = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== ref.current?.pathname) {
      window.scrollTo(1, 0);
    }
    ref.current = location;
  }, [location]);
};

export default useScrollToTop;
