import React, { useState, memo, useCallback } from "react";
import Icon from "./icons";
import CustomSelect from "./custom-select";

const SlotQuantity = ({
  data,
  showAddCart,
  showAvailability,
  maxQuantity = 7,
  onSubmit,
  isDisabled = false,
}) => {
  const [quantity, setQuantity] = useState("");
  const generateOption = useCallback(() => {
    let options = [];
    const mq = Math.min(maxQuantity, Number(data.disp));

    for (let i = 1; i <= mq; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  }, [data, maxQuantity]);

  const onChange = (e) => {
    console.log("onChange SlotQuantity", e);
    setQuantity(e);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minWidth: 110,
      fontSize: "15px",
    }),
  };

  return (
    <>
      <div className="slot-quantity d-flex">
        <CustomSelect
          className="slot-quantity__select"
          placeholder="Quantità"
          options={generateOption()}
          onChange={onChange}
          isDisabled={isDisabled}
          customStyles={customStyles}
        ></CustomSelect>
        {showAddCart && (
          <button
            title="Prenota"
            disabled={Number(quantity?.value) > 0 ? false : true}
            onClick={() => onSubmit(data, quantity)}
            className="slot-quantity__btn slot-quantity__btn--add align-self-stretch"
          >
            <Icon name="addcart" />
          </button>
        )}
      </div>
      {showAvailability && (
        <span className="slot-quantity__availability">
          Posti disponibili:<strong> {data.disp}</strong>
        </span>
      )}
    </>
  );
};

export default memo(SlotQuantity);
