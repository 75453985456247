import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "@aws-amplify/auth";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import UserContext from "../context/user";
import { useNavigazione } from "../context/navigazione";
// import { getErrorMessage } from "../utils/cognito-errors";

const Profilo = () => {
  const { userProfile, login } = useContext(UserContext);
  const navigate = useNavigate();
  const { getPathByTemplate } = useNavigazione();
  const bigliettiPrenotazioniLink = getPathByTemplate("bigliettiPrenotazioni");
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: userProfile.attributes.email,
      first_name:
        userProfile.attributes["custom:first_name"] ||
        userProfile.attributes.name,
      last_name:
        userProfile.attributes["custom:last_name"] ||
        userProfile.attributes.family_name,
      cap: userProfile.attributes["custom:cap"] || "",
      mobile: userProfile.attributes["custom:mobile"] || "",
    },
  });

  const onSubmit = async (values) => {
    try {
      await Auth.updateUserAttributes(userProfile, {
        "custom:first_name": values.first_name,
        "custom:last_name": values.last_name,
        "custom:cap": values.cap,
        "custom:mobile": values.mobile,
      });
      const user = await Auth.currentAuthenticatedUser();
      login(user);
      navigate(bigliettiPrenotazioniLink);
    } catch (err) {
      console.error("Error: ", err);
      setError("server", { message: "Si è verificato un errore" });
    }
  };

  return (
    <>
      <Header />
      <Breadcrumbs />
      <div role="main" className="main-wrapper mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h1>Profilo</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
              <div className="text-end">*Campi obbligatori</div>
              <div className="login-box">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <form
                      className="form"
                      onSubmit={(e) => {
                        clearErrors("server");
                        handleSubmit(onSubmit)(e);
                      }}
                    >
                      <label className="form__label">
                        Email
                        <input
                          type="email"
                          className="form__input"
                          readOnly
                          {...register("email")}
                        />
                      </label>
                      <label className="form__label">
                        Nome
                        <input
                          className="form__input"
                          {...register("first_name", {
                            required: "Campo obbligatorio",
                          })}
                        />
                      </label>
                      {errors.first_name && (
                        <span>{errors.first_name.message}</span>
                      )}
                      <label className="form__label">
                        Cognome
                        <input
                          className="form__input"
                          {...register("last_name", {
                            required: "Campo obbligatorio",
                          })}
                        />
                      </label>
                      {errors.last_name && (
                        <span>{errors.last_name.message}</span>
                      )}
                      <label className="form__label">
                        CAP<span>*</span>
                        <input
                          className="form__input"
                          {...register("cap", {
                            required: "Campo obbligatorio",
                          })}
                        />
                      </label>
                      {errors.cap && <span>{errors.cap.message}</span>}
                      <label className="form__label">
                        Numero di cellulare
                        <input
                          className="form__input"
                          {...register("mobile")}
                        />
                      </label>
                      <p>
                        Il tuo numero di cellulare potrà essere utilizzato nel
                        rispetto della privacy e della normativa vigente
                        dall'Associazione Festival della Scienza durante il
                        periodo della manifestazione per comunicazioni urgenti
                        riguardo eventuali variazioni e/o annullamenti degli
                        eventi del Festival della Scienza per i quali avrai
                        effettuato una prenotazione.
                      </p>
                      <button
                        className="btn btn--link btn--small align-self-start mt-4"
                        title="Registrati"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}{" "}
                        Aggiorna
                      </button>
                      {errors.server && <span>{errors.server.message}</span>}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profilo;
