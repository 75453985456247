import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import UserContext from "../context/user";
import CustomSelect from "../components/custom-select";

const options = [
  {
    value: "true",
    label: "Aggiungi agli amici",
  },
  {
    value: "false",
    label: "Non aggiungere agli amici",
  },
];

const PASSWORD_REGEX =
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

const InserisciUtente = () => {
  const { callApi } = useContext(UserContext);

  const {
    register,
    setError,
    clearErrors,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      amico: "true",
    },
  });

  const onSubmit = async (values) => {
    clearErrors("server");
    try {
      const data = await callApi("/api/users/inserisci-utente", {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          password: values.password,
          first_name: values.first_name,
          last_name: values.last_name,
          mobile: values.mobile,
          cap: values.cap,
          amico: values.amico === "true",
        }),
      });
      console.log(data);
    } catch (err) {
      console.log("errore inserimento amico:", err);
      setError("server", {
        message: `Si è verificato un errore: ${err?.desc}`,
      });
    }
  };
  return (
    <>
      <Header />
      <Breadcrumbs />
      <div role="main" className="main-wrapper mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h1>Inserisci amico</h1>
            </div>
          </div>
          {isSubmitSuccessful ? (
            <div className="row">
              <div className="col-12">
                <p>Utente inserito</p>
                <p>
                  L'utente può ora loggarsi sul sito con email e password
                  inserite, e dovrà cambiare la password al primo accesso. Potrà
                  aggiornare gli altri dettagli della pagina Profilo.
                </p>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
                <div className="text-end">*Campi obbligatori</div>
                <div className="login-box">
                  <div className="row">
                    <div className="col-md-10 offset-md-1">
                      <form
                        className="form"
                        onSubmit={(e) => {
                          clearErrors("server");
                          handleSubmit(onSubmit)(e);
                        }}
                      >
                        <label className="form__label">
                          Email<span>*</span>
                          <input
                            autoComplete="off"
                            type="email"
                            className="form__input"
                            {...register("email", {
                              required: "Campo obbligatorio",
                            })}
                          />
                        </label>
                        {errors.email && <span>{errors.email.message}</span>}
                        <label className="form__label">
                          Ripeti email<span>*</span>
                          <input
                            type="email2"
                            autoComplete="off"
                            className="form__input"
                            {...register("email2", {
                              required: "Campo obbligatorio",
                              validate: (value, values) =>
                                value === values.email ||
                                "Le email non coincidono",
                            })}
                          />
                        </label>
                        {errors.email2 && <span>{errors.email2.message}</span>}
                        <label className="form__label">
                          Password temporanea<span>*</span>
                          <input
                            type="password"
                            autoComplete="new-password"
                            className="form__input"
                            {...register("password", {
                              required: "Campo obbligatorio",
                              pattern: {
                                value: PASSWORD_REGEX,
                                message:
                                  "Password non valida. La password deve contenere almeno una maiuscola, una minuscola, un numero, un simbolo e almeno 8 caratteri in totale",
                              },
                            })}
                          />
                        </label>
                        {errors.password && (
                          <span>{errors.password.message}</span>
                        )}
                        <label className="form__label">
                          Ripeti Password temporanea<span>*</span>
                          <input
                            type="password"
                            autoComplete="new-password"
                            className="form__input"
                            {...register("password2", {
                              required: "Campo obbligatorio",
                              validate: (value, values) =>
                                value === values.password ||
                                "Le password non coincidono",
                            })}
                          />
                        </label>
                        {errors.password2 && (
                          <span>{errors.password2.message}</span>
                        )}
                        <label className="form__label">
                          Nome<span>*</span>
                          <input
                            className="form__input"
                            {...register("first_name", {
                              required: "Campo obbligatorio",
                            })}
                          />
                        </label>
                        {errors.first_name && (
                          <span>{errors.first_name.message}</span>
                        )}
                        <label className="form__label">
                          Cognome<span>*</span>
                          <input
                            className="form__input"
                            {...register("last_name", {
                              required: "Campo obbligatorio",
                            })}
                          />
                        </label>
                        {errors.last_name && (
                          <span>{errors.last_name.message}</span>
                        )}

                        <label className="form__label">
                          CAP
                          <input className="form__input" {...register("cap")} />
                        </label>
                        {errors.cap && <span>{errors.cap.message}</span>}
                        <label className="form__label">
                          Numero di cellulare
                          <input
                            className="form__input"
                            {...register("mobile")}
                          />
                        </label>
                        <label>Amico</label>
                        <Controller
                          control={control}
                          name="amico"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <CustomSelect
                              ref={ref}
                              className="form__select"
                              placeholder="Amico"
                              options={options}
                              value={options.find((opt) => opt.value === value)}
                              onChange={(e) => {
                                onChange(e?.value);
                              }}
                            />
                          )}
                        />
                        <button
                          className="btn btn--link btn--small align-self-start mt-4"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}{" "}
                          Inserisci
                        </button>
                        {errors.server && <span>{errors.server.message}</span>}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default InserisciUtente;
