import { getErrorInfo } from "../utils/utility";
import classNames from "classnames";

const ErrorMessage = ({ chidren, error }) => {
  console.log("error", error);
  const info = getErrorInfo(error?.code);
  console.log("info", info);
  return (
    <div
      className={classNames(
        "error-message",
        info.variant && `error-message--${info.variant}`
      )}
    >
      {chidren}
      {info.text}
    </div>
  );
};

export default ErrorMessage;
