import { FESTIVAL_YEAR } from "../utils/constants";
import React, { useState, useContext } from "react";
import dayjs from "dayjs";
import "dayjs/locale/it";
import classNames from "classnames";
import { ECOMMERCE_MODE } from "../utils/constants";
import UserContext from "../context/user";
import SlotQuantity from "./slot-quantity";
import BookingModal from "./booking-modal";
import { useCart } from "../hooks/useQueryCart";
import { useNavigazione } from "../context/navigazione";

const DayButton = ({ data, setDaySelected, daySelected }) => {
  const day = dayjs(data.daycode, "YYYYMMDD").locale("it");
  const dayName = day.format("ddd");
  const dayNumber = day.format("D");
  return (
    <button
      onClick={() => setDaySelected(data)}
      title={data.daycode}
      className={classNames(
        "event-slot__day",
        daySelected?.daycode === data.daycode && "event-slot__day--active"
      )}
    >
      <span className="event-slot__day-number">{dayNumber}</span>
      <span
        className={classNames(
          "event-slot__day-name",
          (dayName === "dom" || dayName === "sab" || dayNumber === "1") &&
            "event-slot__day-name--weekend"
        )}
      >
        {dayName}
      </span>
    </button>
  );
};

const EventSlot = ({ data, scuole }) => {
  const { data: cart } = useCart();
  const [daySelected, setDaySelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { isLogged } = useContext(UserContext);
  const { mode } = useNavigazione();

  const [slotSelected, setSlotSelected] = useState(null);
  const [quantity, setQuantity] = useState(null);

  const daysO = data.day?.filter((item) =>
    item.daycode.startsWith(FESTIVAL_YEAR + "10")
  );
  const daysN = data.day?.filter((item) =>
    item.daycode.startsWith(FESTIVAL_YEAR + "11")
  );

  const onSubmit = (slot, quantity) => {
    setSlotSelected(slot);
    setQuantity(quantity);
    setShowModal(true);
  };

  return (
    <div className="event-slot my-4">
      {daysO.length > 0 && (
        <div className="event-slot__month my-2">Ottobre</div>
      )}
      <div className="d-flex gap-2 flex-wrap mb-4">
        {daysO.map((item) => (
          <DayButton
            key={item.daycode}
            data={item}
            daySelected={daySelected}
            setDaySelected={setDaySelected}
          />
        ))}
      </div>
      {daysN.length > 0 && (
        <div className="event-slot__month my-2">Novembre</div>
      )}
      <div className="d-flex gap-2 flex-wrap">
        {daysN.map((item) => (
          <DayButton
            key={item.daycode}
            data={item}
            daySelected={daySelected}
            setDaySelected={setDaySelected}
          />
        ))}
      </div>
      {daySelected && (
        <div className="event-slot__disp mt-4">
          <h3 className="graphic">
            <span className="graphic__label">Orari</span>
            <span className="graphic__line">&#160;</span>
          </h3>
          {daySelected.slot.map((item) => (
            <div
              className="d-flex flex-row justify-content-between"
              key={`s_${item.id_slot}`}
            >
              <div> {item.orario} </div>
              <div className="mb-4">
                {scuole ? (
                  <div>
                    {item.slot_chiuso === "SI"
                      ? "Non disponibile"
                      : "Disponibile: Sì"}
                  </div>
                ) : (
                  <>
                    {(() => {
                      if (
                        cart &&
                        isLogged &&
                        mode === ECOMMERCE_MODE &&
                        item.slot_chiuso === "NO"
                      ) {
                        if (item.slot_premium === "SI" && !cart.gratuitapremium)
                          return (
                            <span className="event-slot__availability">
                              Posti disponibili:<strong> {item.disp}</strong>
                              <br />
                              <em>
                                <strong>
                                  Posti riservati ad abbonamenti Premium
                                </strong>
                              </em>
                            </span>
                          );
                        else
                          return (
                            <SlotQuantity
                              key={item.id_slot}
                              data={item}
                              showAvailability={true}
                              showAddCart={true}
                              maxQuantity={cart.gratuitapremium ? 1 : 9}
                              onSubmit={onSubmit}
                            />
                          );
                      } else if (
                        !isLogged ||
                        mode !== ECOMMERCE_MODE ||
                        item.slot_chiuso === "SI"
                      )
                        return (
                          <span className="event-slot__availability">
                            Posti disponibili:<strong> {item.disp}</strong>
                          </span>
                        );
                    })()}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {slotSelected && quantity && cart && (
        <BookingModal
          daycode={daySelected.daycode}
          slot={slotSelected}
          key={slotSelected.id_slot}
          quantity={quantity.value}
          show={showModal}
          premium={cart.gratuitapremium}
          hide={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};

export default EventSlot;
