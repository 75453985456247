import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import PageContent from "../components/page-content";
import { usePage } from "../hooks/useQueryCustom";
import Seo from "../components/seo";

const GenericPage = ({ page, breadcrumbs }) => {
  const { data } = usePage(page.link.slice(1));

  // TODO le pagine del menu potrebbero essere prese direttamente dal menu,
  // mentre per news e pagine non linkate andrebbe usato usePage o simile
  return (
    <>
      <Seo title={page.name} description={data?.metaDescription} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper mt-3 " id="main">
        <div className="container">
          {data && (
            <>
              <h1>{data.titoloInPagina}</h1>

              <PageContent data={data} rientro={data.rientroInPagina} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default GenericPage;
