export const ID_CONFERENZA = "2";
export const ID_SPETTACOLO = "3";
export const ID_EVENTO_SPECIALE = "4";
export const ID_MOSTRA = "5";
export const ID_LABORATORIO = "6";
export const ID_EVENTO_OSPITE = "7";
export const FESTIVAL_YEAR = "2024";
export const NOFESTIVAL_MODE = "Fuori Festival";
export const ONLYEDITION_MODE = "Solo testata";
export const EARLYBIRD_MODE = "Early bird";
export const ECOMMERCE_MODE = "Ecommerce";
export const MAX_TICKET = 9;

const URL_BO = import.meta.env.VITE_BO_ADMIN;
export const URL_PDF_ACQUISTO = `${URL_BO}/fadmin/ecommerce/acquisto_web_pdf.php`;
export const URL_PDF_AMICO = `${URL_BO}/pdf/riepilogoAF_web_pdf.php`;
export const URL_PDF_SERVICE = `${URL_BO}/pdf/topdf.php`;

export const FESTIVAL_DAY = {
  ottobre: [
    {
      daycode: "20241024",
      searchcode: "2410",
    },
    {
      daycode: "20241025",
      searchcode: "2510",
    },
    {
      daycode: "20241026",
      searchcode: "2610",
    },
    {
      daycode: "20241027",
      searchcode: "2710",
    },
    {
      daycode: "20241028",
      searchcode: "2810",
    },
    {
      daycode: "20241029",
      searchcode: "2910",
    },
    {
      daycode: "20241030",
      searchcode: "3010",
    },
    {
      daycode: "20241031",
      searchcode: "3110",
    },
  ],
  novembre: [
    {
      daycode: "20241101",
      searchcode: "0111",
    },
    {
      daycode: "20241102",
      searchcode: "0211",
    },
    {
      daycode: "20241103",
      searchcode: "0311",
    },
  ],
};

export const FESTIVAL_DAY_SCUOLE = {
  ottobre: [
    {
      daycode: "20241024",
      searchcode: "2410",
    },
    {
      daycode: "20241025",
      searchcode: "2510",
    },
    {
      daycode: "20241028",
      searchcode: "2810",
    },
    {
      daycode: "20241029",
      searchcode: "2910",
    },
    {
      daycode: "20241030",
      searchcode: "3010",
    },
    {
      daycode: "20241031",
      searchcode: "3110",
    },
  ],
};

export const DISCIPLINE = [
  {
    value: "6",
    label: "Astrofisica, cosmologia e spazio",
  },
  {
    value: "3",
    label: "Chimica e materiali",
  },
  {
    value: "5",
    label: "Energia, tecnologia e ingegneria ",
  },
  {
    value: "4",
    label: "Fisica e materia",
  },
  {
    value: "8",
    label: "Matematica, logica e informatica",
  },
  {
    value: "7",
    label: "Medicina e scienze della salute",
  },
  {
    value: "11",
    label: "Neuroscienze e Psicologia",
  },
  {
    value: "12",
    label: "Robotica, Intelligenza Artificiale e supercalcolo",
  },
  {
    value: "13",
    label: "Scienze agroalimentari",
  },
  {
    value: "1",
    label: "Scienze della Terra, del Mare e dell'Ambiente",
  },
  {
    value: "2",
    label: "Scienze della Vita",
  },
  {
    value: "10",
    label: "Scienze sociali",
  },
  {
    value: "9",
    label: "Scienze umane, arte e filosofia, storia della scienza",
  },
];

export const TIPO_EVENTO = [
  {
    value: ID_CONFERENZA,
    label: "Conferenza",
  },
  {
    value: ID_EVENTO_SPECIALE,
    label: "Evento speciale",
  },
  {
    value: ID_LABORATORIO,
    label: "Laboratorio",
  },
  {
    value: ID_MOSTRA,
    label: "Mostra",
  },
  {
    value: ID_SPETTACOLO,
    label: "Spettacolo",
  },
];

export const target = [
  {
    value: "2",
    label: "4-5 anni",
  },
  {
    value: "3",
    label: "6-7 anni",
  },
  {
    value: "4",
    label: "8-10 anni",
  },
  {
    value: "5",
    label: "11-13 anni",
  },
  {
    value: "6",
    label: "14-15 anni",
  },
  {
    value: "7",
    label: "16-19 anni",
  },
  {
    value: "8",
    label: ">20 anni e oltre",
  },
];

export const TIPO_CONFERENZA = [
  {
    id: "1",
    label: "Lectio Magistralis",
  },
  {
    id: "2",
    label: "Dialogo internazionale",
  },
  {
    id: "3",
    label: "Caffè scientifico",
  },
  {
    id: "4",
    label: "Conversazione",
  },
  {
    id: "5",
    label: "Dialogo",
  },
  {
    id: "6",
    label: "Conferenza/Spettacolo",
  },
  {
    id: "7",
    label: "Evento Speciale",
  },
  {
    id: "8",
    label: "Incontro",
  },
  {
    id: "9",
    label: "Convegno",
  },
  {
    id: "10",
    label: "Seminario",
  },
  {
    id: "11",
    label: "Tavola rotonda",
  },
  {
    id: "13",
    label: "Documentario/Film",
  },
  {
    id: "15",
    label: "Webinar",
    alias: "Digitalk",
  },
  {
    id: "16",
    label: "Webinar",
  },
];

export const TIPO_SPETTACOLO = [
  {
    id: "1",
    label: "Spettacolo teatrale",
  },
  {
    id: "2",
    label: "Documentario",
  },
  {
    id: "3",
    label: "Spettacolo musicale",
  },
  {
    id: "4",
    label: "Teatro di narrazione scientifica",
  },
  {
    id: "5",
    label: "Concerto",
  },
  {
    id: "6",
    label: "Film",
  },
  {
    id: "7",
    label: "Monologo",
  },
  {
    id: "8",
    label: "Teatro per ragazzi",
  },
];

export const TIPO_MOSTRA = [
  {
    id: "1",
    label: "Mostra fotografica",
  },
  {
    id: "2",
    label: "Mostra interattiva",
  },
  {
    id: "3",
    label: "Mostra illustrativa con pannelli",
  },
  {
    id: "4",
    label: "Mostra multimediale",
  },
  {
    id: "5",
    label: "Installazione",
  },
];

export const TIPO_LABORATORIO = [
  {
    id: "1",
    label: "Laboratorio interattivo",
  },
  {
    id: "2",
    label: "Laboratorio dimostrativo",
  },
  {
    id: "3",
    label: "Laboratorio hands-on",
  },
];

export const ERRORS = [
  {
    code: "E3",
    message: {
      text: "E' possibile acquistare un solo biglietto premium",
      variant: "warning",
    },
  },
  {
    code: "E4",
    message: {
      text: "Non si possono acquistare Abbonamenti Premium e Abbonamenti Standard con lo stesso account. Per acquistare altri titoli è necessario usare un account diverso. ",
      variant: "warning",
    },
  },
  {
    code: "E5",
    message: {
      text: "Non si possono acquistare più di 40 biglietti",
      variant: "warning",
    },
  },
  {
    code: "E7",
    message: {
      text: "Per acquistare un premium il carrello deve essere vuoto.",
      variant: "warning",
    },
  },
];

export const PLANET_EARLYBIRD = "358";

export const PLANET_A = [
  { id: "20241024", evplanet: "359" },
  { id: "20241025", evplanet: "360" },
  { id: "20241026", evplanet: "361" },
  { id: "20241027", evplanet: "362" },
  { id: "20241028", evplanet: "363" },
  { id: "20241029", evplanet: "364" },
  { id: "20241030", evplanet: "365" },
  { id: "20241031", evplanet: "366" },
  { id: "20241101", evplanet: "367" },
  { id: "20241102", evplanet: "368" },
];

export const PLANET_B = [
  { id: "2410", evplanet: "45235" },
  { id: "2510", evplanet: "45236" },
  { id: "2610", evplanet: "45237" },
  { id: "2710", evplanet: "45238" },
  { id: "2810", evplanet: "45239" },
  { id: "2910", evplanet: "45240" },
  { id: "3010", evplanet: "45241" },
  { id: "3110", evplanet: "45242" },
  { id: "0111", evplanet: "45243" },
  { id: "0211", evplanet: "45244" },
  { id: "0311", evplanet: "45245" },
];

export const PAGE_MODE_FILTER = [
  {
    mode: NOFESTIVAL_MODE,
    layout: [
      "Programma",
      "Biglietti e prenotazioni",
      "Area clienti",
      "Landing con login",
      "Programma scuole",
      "Early bird",
      "Acquista biglietti",
      "Completa acquisto",
    ],
  },
  {
    mode: ONLYEDITION_MODE,
    layout: [
      "Programma",
      "Biglietti e prenotazioni",
      "Area clienti",
      "Landing con login",
      "Programma scuole",
      "Early bird",
      "Acquista biglietti",
      "Completa acquisto",
    ],
  },
  {
    mode: EARLYBIRD_MODE,
    layout: ["Programma", "Programma scuole", "Acquista biglietti"],
  },
  {
    mode: ECOMMERCE_MODE,
    layout: ["Early bird"],
  },
];
