import { useState, useContext, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "@aws-amplify/auth";
import { getErrorMessage } from "../utils/cognito-errors";
import UserContext from "../context/user";
import ForgotPasswordModal from "./forgot-password-modal";

const LoginForm = () => {
  const location = useLocation();
  const { email = "" } = location.state || {};
  const { login } = useContext(UserContext);
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [user, setUser] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email,
    },
  });

  const onSubmit = async (values) => {
    console.log("onSubmit: ", values);
    if (mustChangePassword) {
      try {
        await Auth.completeNewPassword(user, values.password);
      } catch (err) {
        if (err.code === "NotAuthorizedException") {
          setMustChangePassword(false);
          setUser(null);
          reset();
        }
        setError("server", { message: getErrorMessage(err) });
        console.error("error completeNewPassword: ", err);
        return;
      }
    }
    try {
      const user = await Auth.signIn(values.email, values.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        console.log("Must change password!", user);
        setMustChangePassword(true);
        setUser(user);
        setValue("password", "");
        return;
      }
    } catch (err) {
      console.error("Error in login: ", err);
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
        console.log("UserNotConfirmedException");
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
        console.log("UserNotConfirmedException");
        setValue("password", "");
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
        console.log("NotAuthorizedException");
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        console.log("UserNotFoundException");
      }
      setError("server", { message: getErrorMessage(err) });
      return;
    }

    const loggedUser = await Auth.currentAuthenticatedUser();
    console.log("loggedUser: ", loggedUser);
    login(loggedUser);
  };

  const [forgotModalVisible, setForgotModalVisible] = useState(false);
  const showForgotModal = () => setForgotModalVisible(true);
  const closeForgotModal = useCallback(() => setForgotModalVisible(false), []);

  return (
    <>
      <form
        className="form"
        onSubmit={(e) => {
          clearErrors("server");
          handleSubmit(onSubmit)(e);
        }}
      >
        {mustChangePassword && (
          <div>Per continuare è necessario inserire una nuova password.</div>
        )}
        <label className="form__label">
          E-mail
          <input
            type="email"
            className="form__input"
            readOnly={mustChangePassword}
            {...register("email", {
              required: {
                value: true,
                message: "Campo obbligatorio",
              },
            })}
          />
          {errors.email && (
            <span className="form__error">{errors.email.message}</span>
          )}
        </label>

        <label className="form__label">
          Password
          <input
            type="password"
            className="form__input"
            {...register("password", {
              required: {
                value: true,
                message: "Campo obbligatorio",
              },
            })}
          />
          {errors.password && (
            <span className="form__error">{errors.password.message}</span>
          )}
        </label>

        {mustChangePassword && (
          <>
            <label className="form__label">
              Ripeti Password<span>*</span>
              <input
                type="password"
                className="form__input"
                {...register("password2", {
                  required: {
                    value: true,
                    message: "Campo obbligatorio",
                  },
                  validate: (value, values) =>
                    value === values.password || "Le password non coincidono",
                })}
              />
            </label>
            {errors.password2 && <span>{errors.password2.message}</span>}
          </>
        )}
        <div className="text-center">
          <button
            className="btn btn--link btn--small align-self-start mt-4"
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}{" "}
            Entra
          </button>
        </div>
        {errors.server && <span>{errors.server.message}</span>}
        <button
          type="button"
          className="link mt-4 align-self-start"
          onClick={showForgotModal}
        >
          Password dimenticata
        </button>
      </form>
      <ForgotPasswordModal
        show={forgotModalVisible}
        onHide={closeForgotModal}
        email={email}
        key={forgotModalVisible}
      />
    </>
  );
};

export default LoginForm;
