import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Steps from "../components/steps";
import CustomSelect from "../components/custom-select";
import Icon from "../components/icons";
import ErrorMessage from "../components/error-message";
import { MAX_TICKET } from "../utils/constants";
import { useAddTicket, useCart, useRemoveTicket } from "../hooks/useQueryCart";
import { usePage } from "../hooks/useQueryCustom";
import PageContent from "../components/page-content";
import UserContext from "../context/user";
import { useContext } from "react";
import { useNavigazione } from "../context/navigazione";
import { getTicketOptions, isValidDay, getTicketDesc } from "../utils/utility";

const EarlyBird = ({ page, breadcrumbs }) => {
  const addTicket = useAddTicket();
  const { data } = usePage(page.link.slice(1));
  const { isAmico } = useContext(UserContext);
  const { data: cart, isLoading } = useCart();
  const { getPathByTemplate, earlyBirdPremium } = useNavigazione();
  const linkCompletaAcquisto = getPathByTemplate("carrello");
  const removeTicket = useRemoveTicket();
  const navigate = useNavigate();

  const {
    register,
    watch,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { tck: "abb" },
  });
  const watchTck = watch("tck");

  const onSubmit = async (values) => {
    const payload = values;
    if (payload.tck === "premium") {
      payload.num = 1;
    }
    try {
      addTicket.mutate(payload);
      if (!addTicket?.isError) {
        navigate(linkCompletaAcquisto);
      }
    } catch (err) {
      console.error("Error: ", err);
      setError("server", { message: "Si è verificato un errore" });
    }
  };

  const onRemoveTck = async () => {
    //e.preventDefault();
    try {
      removeTicket.mutate();
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  return (
    <>
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper  mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h1>Biglietti Early Bird</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Steps selected={1} />
            </div>
          </div>
          {addTicket.isLoading || isLoading || removeTicket.isLoading ? (
            <div className="col-12">
              <div className="text-center my-5">
                <Icon name="spinner" className="icon--big icon--animated" />
              </div>
            </div>
          ) : (
            <div className="row">
              {cart?.tck ? (
                <div className="col-12">
                  <div className="bg-gray p-4 mb-4">
                    <h2 className="h3 graphic">
                      <span className="graphic__label graphic__label--gray-light">
                        Biglietti nel carrello
                      </span>
                      <span className="graphic__line">&nbsp;</span>
                    </h2>
                    <div className="row">
                      <div className="col-xs-4 col-sm-4">
                        <strong>Tipologia</strong>
                      </div>
                      <div className="col-xs-4 col-sm-3">
                        <strong>Periodo</strong>
                      </div>
                      <div className="col-xs-4 col-sm-2">
                        <strong>Quantità</strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-4 col-sm-4">
                        {cart.tck === "abb" && "Abbonamento"}
                        {cart.tck === "premium" && "Abbonamento premium"}
                        {isValidDay(cart.tck) && "Giornaliero"}
                      </div>
                      <div className="col-xs-4 col-sm-3">
                        {cart.tck === "abb" && "tutti i giorni del Festival"}
                        {cart.tck === "premium" &&
                          "tutti i giorni del Festival"}
                        {isValidDay(cart.tck) && getTicketDesc(cart.tck)}
                      </div>
                      <div className="col-xs-4 col-sm-2">{cart.num}</div>
                      <div className="col-12 col-sm-2">
                        <button
                          className="btn"
                          onClick={onRemoveTck}
                          type="button"
                        >
                          Rimuovi
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <h2 className="h3 graphic mt-5">
                          <span className="graphic__label graphic__label--gray-light">
                            Cosa fare ora
                          </span>
                          <span className="graphic__line">&nbsp;</span>
                        </h2>
                        <p>
                          Inserire i tuoi dati e{" "}
                          <Link to={linkCompletaAcquisto}>
                            completare l'acquisto
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-12 col-md-6">
                    <div className="bg-gray p-4 mb-4">
                      <form
                        name="biglietti"
                        className="form"
                        onSubmit={(e) => {
                          clearErrors("server");
                          handleSubmit(onSubmit)(e);
                        }}
                      >
                        <fieldset>
                          <legend>
                            <h2 className="h3 graphic">
                              <span className="graphic__label graphic__label--gray-light">
                                Tipologia
                              </span>
                              <span className="graphic__line">&nbsp;</span>
                            </h2>
                          </legend>

                          <div>
                            <label htmlFor="abb" className="form__radio-label">
                              <input
                                className="form__radio-input"
                                type="radio"
                                {...register("tck")}
                                value="abb"
                                id="abb"
                              />
                              <span className="form__custom-radio" />
                              Abbonamento standard
                            </label>
                            <div className="my-3 form__radio-desc">
                              Prezzo in promozione Early Bird: 14€ + 1,40€
                              (diritto di prevendita) = <strong>15,40€</strong>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="premium"
                              className="form__radio-label"
                            >
                              <input
                                className="form__radio-input"
                                type="radio"
                                disabled={isAmico || !earlyBirdPremium}
                                {...register("tck")}
                                value="premium"
                                id="premium"
                              />
                              <span className="form__custom-radio" />
                              Abbonamento Premium
                            </label>

                            <div className="my-3 form__radio-desc">
                              {isAmico ? (
                                <p>
                                  <u>
                                    Come amico non puoi acquistare abbonamenti
                                    premium
                                  </u>
                                </p>
                              ) : !earlyBirdPremium ? (
                                <p>
                                  <u>Disponibilità esaurita</u>
                                </p>
                              ) : null}
                              Prezzo in promozione Early Bird: 18€ + 1,80€
                              (diritto di prevendita) = <strong>19,80€</strong>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend>
                            <h2 className="h3 graphic">
                              <span className="graphic__label graphic__label--gray-light">
                                Quantità
                              </span>
                              <span className="graphic__line">&nbsp;</span>
                            </h2>
                          </legend>
                          {watchTck === "abb" ? (
                            <>
                              <Controller
                                control={control}
                                name="num"
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <CustomSelect
                                    key="q-biglietti"
                                    placeholder="Quantità"
                                    className="form__select form__select--small"
                                    options={getTicketOptions(MAX_TICKET)}
                                    onChange={(e) => {
                                      onChange(e?.value);
                                    }}
                                    onBlur={onBlur}
                                    onSubmit={() => void 0}
                                  />
                                )}
                              />
                              {errors.num?.type === "required" && (
                                <p role="alert" className="red my-2">
                                  Quantità mancante
                                </p>
                              )}
                            </>
                          ) : (
                            <div>
                              L’Abbonamento Premium è personale e puoi
                              acquistarne solo uno per account.
                            </div>
                          )}
                        </fieldset>
                        <button
                          className="btn btn--link btn--small align-self-lg-end mt-4"
                          title="Aggiung biglietti"
                          disabled={isSubmitting || !watchTck}
                        >
                          {isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}{" "}
                          Aggiungi al carrello
                          <Icon className="ms-2" name="addcart" />
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    {data && <PageContent data={data} />}
                  </div>
                </>
              )}
            </div>
          )}
          <div className="row">
            <div className="col-12">
              {addTicket.isError ? (
                <ErrorMessage error={addTicket.error} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EarlyBird;
