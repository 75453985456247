import { Fragment, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useNavigazione } from "../context/navigazione";
import Footer from "../components/footer";
import PrivateRoute from "../components/private-route";
import GenericPage from "./generic-page";
import Articolo from "./article";
import EdizioniPrecedenti from "./edizioni-precedenti";
import Home from "./home";
import Programma from "./programma";
import Protagonisti from "./protagonisti";
import ProgrammaScuole from "./programma-scuole";
import ProgrammaExtra from "./programma-extra";
import AreaClienti from "./area-clienti";
import BigliettiPrenotazioni from "./biglietti-prenotazioni";
import Event from "./event";
import Registrazione from "./registrazione";
import Profilo from "./profilo";
import Biglietti from "./biglietti";
import EarlyBird from "./early-bird";
import Carrello from "./carrello";
import Ordini from "./ordini";
import Premium from "./premium";
import ConfermaRegistrazione from "./conferma-registrazione";
import PasswordDimenticata from "./password-dimenticata";
import InserisciUtente from "./inserisci-utente";
import NotFound from "./not-found";
import Newsletter from "./newsletter";
import Sponsor from "./sponsor";
import BannerSponsor from "../components/banner-sponsor";
import ScriviAlFestival from "./scrivi-al-festival";
import CookiePolicy from "./cookie-policy";
import CambiaPassword from "./cambia-password";

const PageTemplate = ({ page }) => {
  const breadcrumb = [
    {
      label: "Home",
      link: "/",
    },
  ]
    .concat(
      page.parent
        ? {
            label: page.parent.name,
            link: page.parent.link,
          }
        : []
    )
    .concat([
      {
        label: page.name,
      },
    ]);
  return (
    {
      generic: (
        <GenericPage page={page} breadcrumbs={breadcrumb} key={page.link} />
      ),
      archivio: (
        <EdizioniPrecedenti
          page={page}
          breadcrumbs={breadcrumb}
          key={page.link}
        />
      ),
      home: <Home page={page} key={page.link} />,
      programma: (
        <Programma page={page} breadcrumbs={breadcrumb} key={page.link} />
      ),
      protagonisti: (
        <Protagonisti page={page} breadcrumbs={breadcrumb} key={page.link} />
      ),
      programmaScuole: (
        <ProgrammaScuole page={page} breadcrumbs={breadcrumb} key={page.link} />
      ),
      programmaExtra: (
        <ProgrammaExtra page={page} breadcrumbs={breadcrumb} key={page.link} />
      ),
      areaClienti: (
        <AreaClienti page={page} breadcrumbs={breadcrumb} key={page.link} />
      ),
      landingLogin: (
        <AreaClienti page={page} breadcrumbs={breadcrumb} key={page.link} />
      ),
      bigliettiPrenotazioni: (
        <PrivateRoute
          component={
            <BigliettiPrenotazioni
              page={page}
              breadcrumbs={breadcrumb}
              key={page.link}
            />
          }
        />
      ),
      newsletter: (
        <Newsletter page={page} breadcrumbs={breadcrumb} key={page.link} />
      ),
      sponsor: <Sponsor page={page} breadcrumbs={breadcrumb} key={page.link} />,
      scriviFestival: (
        <ScriviAlFestival
          page={page}
          breadcrumbs={breadcrumb}
          key={page.link}
        />
      ),
      earlyBird: (
        <PrivateRoute
          component={
            <EarlyBird page={page} breadcrumbs={breadcrumb} key={page.link} />
          }
        />
      ),
      biglietti: (
        <PrivateRoute
          component={
            <Biglietti page={page} breadcrumbs={breadcrumb} key={page.link} />
          }
        />
      ),
      premium: (
        <PrivateRoute
          component={
            <Premium page={page} breadcrumbs={breadcrumb} key={page.link} />
          }
        />
      ),
      carrello: (
        <PrivateRoute
          component={
            <Carrello page={page} breadcrumbs={breadcrumb} key={page.link} />
          }
        />
      ),
      ordini: (
        <PrivateRoute
          component={
            <Ordini page={page} breadcrumbs={breadcrumb} key={page.link} />
          }
        />
      ),
    }[page.template] || null // <Error msg={`Missing template ${page.pageTemplate}`} key={page.link} />
  );
};

const AppRoutes = () => {
  const {
    pages,
    sponsor: showSponsor,
    footer,
    isLoading,
    getPageByTemplate,
  } = useNavigazione();
  const programmaPage = getPageByTemplate("programma");
  const navigate = useNavigate();

  useEffect(() => {
    if (programmaPage && !window.LinkEvento) {
      window.LinkEvento = (id) => {
        navigate(`${programmaPage.link}/id-${id}`);
      };
    }
  }, [programmaPage, navigate]);

  return !isLoading ? (
    <>
      <Routes>
        {pages.map((item) => (
          <Fragment key={item.link}>
            {item.template && (
              <Route path={item.link} element={<PageTemplate page={item} />} />
            )}
            {item?.pages
              ?.filter((i) => i.template && i.link !== item.link)
              .map((sitem) => (
                <Route
                  path={sitem.link}
                  element={<PageTemplate page={sitem} />}
                  key={sitem.link}
                />
              ))}
          </Fragment>
        ))}

        {programmaPage?.link && (
          <Route path={`${programmaPage.link}/:slug`} element={<Event />} />
        )}

        <Route
          path="/profilo"
          element={<PrivateRoute component={<Profilo />} />}
        />

        <Route
          path="/inserisci-utente"
          element={<PrivateRoute component={<InserisciUtente />} />}
        />
        <Route path="/registrazione" element={<Registrazione />} />
        <Route
          path="/conferma-registrazione"
          element={<ConfermaRegistrazione />}
        />
        <Route path="/cambia-password" element={<CambiaPassword />} />
        <Route path="/password-dimenticata" element={<PasswordDimenticata />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path={`/news/:slug`} element={<Articolo />} />
        <Route path="/site/*" element={<Navigate replace="true" to="/" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer data={footer} />
      {showSponsor && <BannerSponsor />}
    </>
  ) : (
    <div></div>
  );
};

export default AppRoutes;
