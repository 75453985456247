import Header from "../components/header";
import ForgotPassword from "../components/forgot-password";
// import Seo from "../components/seo";

const CambiaPassword = () => {
  return (
    <>
      <Header />
      <div role="main" className="main-wrapper mt-3" id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Password dimenticata</h1>
              <p>
                Inserisci l'indirizzo email di registrazione. Se risulterà
                registrato, ti invieremo un link per poter cambiare la password.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
              <ForgotPassword />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CambiaPassword;
