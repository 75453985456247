import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Auth } from "@aws-amplify/auth";
import { useLocation, useNavigate } from "react-router-dom";

const UserContext = createContext({
  userProfile: undefined,
  isLogged: false,
  isAmico: false,
  isAdmin: false,
  login: () => 0,
  logout: () => 0,
  callApi: async (url = "", options = {}) => new Response({}),
});

export const UserProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState();
  const [isLogged, setIsLogged] = useState();
  //console.log("user islogged: ", isLogged);

  const logout = useCallback(async () => {
    try {
      await Auth.signOut();
      setUserProfile();
      setIsLogged(false);
    } catch (err) {
      console.error("Logout err: ", err);
    }
  }, []);

  const login = useCallback(async (user) => {
    setUserProfile(user);
    setIsLogged(true);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userProfile &&
      !userProfile.attributes?.["custom:cap"] &&
      !["/profilo", "/profilo/"].includes(location.pathname)
    ) {
      navigate("/profilo");
    }
  }, [userProfile, location, navigate]);

  useEffect(() => {
    const loadUser = async () => {
      //console.log("loaduser");
      try {
        const user = await Auth.currentAuthenticatedUser();
        //console.log("loaduser", user);
        login(user);
      } catch (err) {
        console.warn("err", err);
        setIsLogged(false);
      }
    };
    loadUser();
  }, [login]);

  const callApi = useCallback(
    async (path, options = {}) => {
      const { headers, ...otherOptions } = options;
      console.log("callApi");
      const response = await fetch(path, {
        headers: {
          Authorization: userProfile?.signInUserSession.idToken.jwtToken,
          ...headers,
        },
        ...otherOptions,
      });
      if (response.ok) {
        return await response.json();
      } else {
        if (response.status === 401) {
          console.error("Authentication error");
          await logout();
        }
        const data = await response.json();
        const error = new Error(
          `Error calling ${path} ${response.status} ${response.statusText}`
        );
        error.status = response.status;
        error.desc = data?.error;
        error.code = data?.code;
        throw error;
      }
    },
    [userProfile, logout]
  );

  const contextValue = useMemo(
    () => ({
      isLogged,
      isAmico:
        !!userProfile?.signInUserSession?.idToken?.payload[
          "cognito:groups"
        ]?.includes("amici"),
      isAdmin:
        !!userProfile?.signInUserSession?.idToken?.payload[
          "cognito:groups"
        ]?.includes("amministratori"),
      userProfile,
      login,
      logout,
      callApi,
    }),
    [isLogged, userProfile, login, logout, callApi]
  );
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserContext;
