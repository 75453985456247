import { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import { getErrorMessage } from "../utils/cognito-errors";
import UserContext from "../context/user";
import { useNavigazione } from "../context/navigazione";

const Registrazione = () => {
  const { isLogged } = useContext(UserContext);
  const { getPathByTemplate } = useNavigazione();
  const bigliettiPrenotazioniLink = getPathByTemplate("bigliettiPrenotazioni");
  const linkAreaClienti = getPathByTemplate("areaClienti");

  const {
    register,
    setError,
    getValues,
    clearErrors,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
  } = useForm();

  const onSubmit = async (values) => {
    clearErrors("server");
    try {
      const { user } = await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
          name: values.name,
          family_name: values.family_name,
          "custom:cap": values.cap,
          ...(values.mobile && {
            "custom:mobile": values.mobile,
          }),
        },
        clientMetadata: {
          from: "www.festivalscienza.it",
        },
      });
      console.log(user);
    } catch (err) {
      console.log("error signing up:", err);
      setError("server", { message: err.code });
    }
  };
  return !isLogged ? (
    <>
      <Header />
      <Breadcrumbs />
      <div role="main" className="main-wrapper mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h1>Registrazione</h1>
            </div>
          </div>
          {isSubmitSuccessful ? (
            <div className="row">
              <div className="col-12">
                <p>Grazie per esserti registrato.</p>
                <p>
                  Controlla la posta, eventualmente anche nella cartella di
                  spam: ti abbiamo mandato una mail di conferma, e cliccando
                  sull'indirizzo che troverai nella mail verrà completata la
                  registrazione.
                </p>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
                <div className="text-end">*Campi obbligatori</div>
                <div className="login-box">
                  <div className="row">
                    <div className="col-md-10 offset-md-1 px-4 px-md-3">
                      <form
                        className="form"
                        onSubmit={(e) => {
                          clearErrors("server");
                          handleSubmit(onSubmit)(e);
                        }}
                      >
                        <label className="form__label">
                          Email<span>*</span>
                          <input
                            type="email"
                            className="form__input"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Campo obbligatorio",
                              },
                            })}
                          />
                          {errors.email && (
                            <span className="form__error">
                              {errors.email.message}
                            </span>
                          )}
                        </label>

                        <label className="form__label">
                          Ripeti email<span>*</span>
                          <input
                            type="email2"
                            className="form__input"
                            {...register("email2", {
                              required: {
                                value: true,
                                message: "Campo obbligatorio",
                              },
                              validate: (value, values) =>
                                value === values.email ||
                                "Le email non coincidono",
                            })}
                          />
                          {errors.email2 && (
                            <span className="form__error">
                              {errors.email2.message}
                            </span>
                          )}
                        </label>

                        <label className="form__label">
                          Password<span>*</span>
                          <input
                            type="password"
                            className="form__input"
                            {...register("password", {
                              required: {
                                value: true,
                                message: "Campo obbligatorio",
                              },
                            })}
                          />
                          {errors.password && (
                            <span className="form__error">
                              {errors.password.message}
                            </span>
                          )}
                        </label>

                        <label className="form__label">
                          Ripeti Password<span>*</span>
                          <input
                            type="password"
                            className="form__input"
                            {...register("password2", {
                              required: {
                                value: true,
                                message: "Campo obbligatorio",
                              },
                              validate: (value, values) =>
                                value === values.password ||
                                "Le password non coincidono",
                            })}
                          />
                          {errors.password2 && (
                            <span className="form__error">
                              {errors.password2.message}
                            </span>
                          )}
                        </label>

                        <label className="form__label">
                          Nome<span>*</span>
                          <input
                            className="form__input"
                            {...register("name", {
                              required: {
                                value: true,
                                message: "Campo obbligatorio",
                              },
                            })}
                          />
                          {errors.name && (
                            <span className="form__error">
                              {errors.name.message}
                            </span>
                          )}
                        </label>

                        <label className="form__label">
                          Cognome<span>*</span>
                          <input
                            className="form__input"
                            {...register("family_name", {
                              required: {
                                value: true,
                                message: "Campo obbligatorio",
                              },
                            })}
                          />
                          {errors.family_name && (
                            <span className="form__error">
                              {errors.family_name.message}
                            </span>
                          )}
                        </label>

                        <label className="form__label">
                          CAP<span>*</span>
                          <input
                            className="form__input"
                            {...register("cap", {
                              required: {
                                value: true,
                                message: "Campo obbligatorio",
                              },
                            })}
                          />
                          {errors.cap && (
                            <span className="form__error">
                              {errors.cap.message}
                            </span>
                          )}
                        </label>

                        <label className="form__label">
                          Numero di cellulare
                          <input
                            className="form__input"
                            {...register("mobile")}
                          />
                        </label>
                        <p>
                          Il tuo numero di cellulare potrà essere utilizzato nel
                          rispetto della privacy e della normativa vigente
                          dall'Associazione Festival della Scienza durante il
                          periodo della manifestazione per comunicazioni urgenti
                          riguardo eventuali variazioni e/o annullamenti degli
                          eventi del Festival della Scienza per i quali avrai
                          effettuato una prenotazione.
                        </p>
                        <button
                          className="btn btn--link btn--small align-self-start mt-4"
                          title="Registrati"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}{" "}
                          Registrati
                        </button>
                        {errors.server && (
                          <div>
                            {errors.server.message ===
                            "UsernameExistsException" ? (
                              <span>
                                L'indirizzo email {getValues("email")} risulta
                                già registrato.
                                <br />
                                Sei già registato al sito
                                www.festivalscienza.online con questa email?{" "}
                                <br />
                                In questo caso torna alla pagina di{" "}
                                <Link to={linkAreaClienti}>login</Link> e
                                utlizza le stesse credenziali. <br /> Se invece
                                non ti ricordi le credenziali puoi utlizzare il
                                servizio di{" "}
                                <Link
                                  to="/cambia-password"
                                  preventScrollReset={true}
                                >
                                  password dimenticata.
                                </Link>
                              </span>
                            ) : (
                              <span>{getErrorMessage(errors.server)}</span>
                            )}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <Navigate to={bigliettiPrenotazioniLink} replace={true} />
  );
};
export default Registrazione;
