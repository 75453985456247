import { Link } from "react-router-dom";

const Breadcrumbs = ({ data }) => {
  return (
    <div className="container d-print-none d-none d-md-block mt-3 mt-lg-5">
      {data && (
        <div className="row">
          <div className="col-sm-12">
            <div
              aria-label="breadcrumb"
              role="navigation"
              className="breadcrumbs"
              id="breadcrumbs"
            >
              <ol
                itemScope="itemscope"
                itemType="https://schema.org/BreadcrumbList"
                className="breadcrumbs__list clearing"
              >
                {data.map((item, index) => (
                  <li
                    key={index}
                    itemProp="itemListElement"
                    itemScope="itemscope"
                    itemType="https://schema.org/ListItem"
                    className="breadcrumbs__item"
                  >
                    <div aria-level={index + 1} itemProp="item">
                      {index === data.length - 1 ? (
                        <span itemProp="name">{item.label}</span>
                      ) : (
                        <>
                          <Link
                            className="breadcrumbs__link"
                            itemProp="url"
                            title={item.label}
                            to={item.link}
                          >
                            <span itemProp="name">{item.label}</span>
                          </Link>
                          &gt;
                        </>
                      )}
                    </div>
                    <meta content={index + 1} itemProp="position" />
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Breadcrumbs;
