import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { Link } from "react-router-dom";
import { useNavigazione } from "../context/navigazione";

const renderOptions = (links, getLink) => ({
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const attributes = {
        href: node.data?.uri,
      };
      if (node.data?.uri.startsWith("http")) {
        attributes.target = "_blank";
        attributes.rel = "noopener noreferrer";
      }
      return <a {...attributes}>{children}</a>;
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const { id } = node.data.target.sys;
      const hyperlink = links.entries?.hyperlink?.find((i) => i.sys.id === id);
      return hyperlink ? <Link to={getLink(hyperlink)}>{children}</Link> : null;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { id } = node.data.target.sys;
      const block = links.entries.block.find((i) => i.sys.id === id);
      return block ? (
        <div dangerouslySetInnerHTML={{ __html: block.html }} />
      ) : null;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const { id } = node.data.target.sys;
      const inline = links.assets.hyperlink.find((i) => i.sys.id === id);
      return inline ? (
        <a download={inline.title} href={inline.url}>
          {children}
        </a>
      ) : null;
    },
  },
});

const ContentfulRichText = ({ data, id, rientro }) => {
  const { getLink } = useNavigazione();
  return (
    <div className="row">
      <div className={`${rientro ? "col-12 offset-md-2 col-md-8 " : "col-12"}`}>
        {documentToReactComponents(
          data.json,
          renderOptions(data.links, getLink)
        )}
      </div>
    </div>
  );
};

export default ContentfulRichText;
