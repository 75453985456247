import Modal from "react-overlays/Modal";
import Icon from "./icons";
import ForgotPassword from "./forgot-password";

const renderBackdrop = () => <div className="modal__external"></div>;

const ForgotPasswordModal = ({ show, onHide, email: formEmail }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="modal__internal d-flex flex-column"
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <>
        <button aria-label="close" className="modal__close" onClick={onHide}>
          <Icon className="ms-2" name="xmark" />
        </button>
        <div className="modal__header">
          <h3>Password dimenticata</h3>
        </div>
        <p>
          Inserisci l'indirizzo email di registrazione. Se risulterà registrato,
          ti invieremo un link per poter cambiare la password.
        </p>
        <div className="my-3 modal__body">
          <ForgotPassword email={formEmail} />
        </div>
      </>
    </Modal>
  );
};

export default ForgotPasswordModal;
