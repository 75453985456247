import fetchJsonp from "fetch-jsonp";
const URL_BO = import.meta.env.VITE_BO_ADMIN;

export const placeholders = (n = 4, key = "id") =>
  new Array(n).fill(0).map((_, i) => ({ [key]: i, placeholder: true }));

export const getRelatori = async () => {
  //const response = await fetch("/mock/relatori.json");
  const response = await fetch(`/api/relatori?sezione_fe=1`);
  const data = await response.json();
  return data;
};

export const getProtagonisti = async (letter) => {
  const response = await fetch(
    `/api/relatori?sezione_fe=1&bio=1&startsWith=${letter}`
  );
  const data = await response.json();
  return data;
};

export const getLuoghi = async () => {
  //const response = await fetch("/mock/luoghi.json");
  const response = await fetch("/api/locations?sezione_fe=1");
  const data = await response.json();
  return data;
};

export const search = async (params, sezionefe = "1", callApi) => {
  console.log("search params", params.toString());
  if (params.get("fav") === "true") {
    const from = params.get("from") || "0";
    const response = await callApi(`/api/search-favourites?from=${from}`);
    return response;
  } else {
    const qs = params.toString() || "order_r=titolo";
    const response = await fetch(`/api/search?sezione_fe=${sezionefe}&${qs}`);
    const data = await response.json();
    return data;
  }
};

export const getEventSlugById = async (id) => {
  console.log("getEventSlugById: ", id);
  const response = await fetch(`/events/id/${id}.json`);
  if (!response.ok) {
    throw new Error(`Error ${response.status} ${response.statusText}`);
  }
  const data = await response.json();
  return data?.slug;
};

export const getEvent = async (slug) => {
  console.log("getEvent", slug);
  try {
    const response = await fetch(`/events/${slug}.json`);
    if (!response.ok) {
      throw new Error(`Error ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (e) {
    return null;
  }
};

export const getBooking = async (id, isAmico) => {
  console.log("fetch slot", id, isAmico);
  const methodName = isAmico ? "slotaf_finder.php" : "slot_finder.php";
  const bookingUrl = `${URL_BO}/fadmin/ecommerce/${methodName}?id_evento=${id}`;
  const response = await fetchJsonp(bookingUrl);
  //const bookingUrl = `/mock/slot.json`;
  //const response = await fetch(bookingUrl);
  const data = await response.json();

  if (data.error_code) {
    //gestione errore applicativo
    throw new Error(data.error_code);
  }

  return data;
};
