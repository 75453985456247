import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "@aws-amplify/auth";
// import Breadcrumbs from "../components/breadcrumbs";
import { getErrorMessage } from "../utils/cognito-errors";
import UserContext from "../context/user";
import Header from "../components/header";
import { useNavigazione } from "../context/navigazione";

// import Seo from "../components/seo";

const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/;

const PasswordDimenticata = () => {
  const [params] = useSearchParams();
  const code = params.get("code");
  const email = params.get("email");
  const navigate = useNavigate();
  const { getPathByTemplate } = useNavigazione();
  const bigliettiPrenotazioniLink = getPathByTemplate("bigliettiPrenotazioni");

  const { login } = useContext(UserContext);

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email },
  });

  useEffect(() => {
    if (!email || !code) {
      navigate(bigliettiPrenotazioniLink);
    }
  }, [code, email, navigate, bigliettiPrenotazioniLink]);

  const submit = async (values) => {
    try {
      await Auth.forgotPasswordSubmit(values.email, code, values.password, {
        from: "www.festivalscienza.it",
      });
    } catch (err) {
      console.error("error forgotPasswordSubmit: ", err);
      setError("server", { message: getErrorMessage(err) });
      return;
    }
    try {
      await Auth.signIn(values.email, values.password);
    } catch (err) {
      console.error("Error in login: ", err);
      setError("server", { message: getErrorMessage(err) });
      return;
    }
    const loggedUser = await Auth.currentAuthenticatedUser();
    login(loggedUser);
    clearErrors("server");
    navigate(bigliettiPrenotazioniLink);
  };

  return (
    <>
      <Header />
      <div role="main" className="main-wrapper mt-3" id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Cambia password</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
              <div className="login-box">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <form
                      className="form"
                      onSubmit={(e) => {
                        clearErrors("server");
                        handleSubmit(submit)(e);
                      }}
                    >
                      <label className="form__label">
                        E-mail
                        <input
                          type="text"
                          readOnly={true}
                          className="form__input"
                          {...register("email", {
                            required: "Email richiesta",
                          })}
                        />
                      </label>
                      <div>
                        <label className="form__label">
                          Password
                          <input
                            type="password"
                            placeholder=""
                            disabled={isSubmitting}
                            maxLength={320}
                            className="form__input"
                            {...register("password", {
                              required: "Password richiesta",
                              pattern: {
                                value: PASSWORD_REGEX,
                                message: "Formato password non valido",
                              },
                            })}
                          />
                        </label>
                        {errors.password ? (
                          <div className="form__error">
                            {errors.password.message}
                          </div>
                        ) : null}
                        <div className="text-small mt-2">
                          <p className="mb-0">
                            La password deve essere di almeno 8 caratteri, deve
                            avere almeno una maiuscola, una minuscola, un numero
                            e un simbolo
                          </p>
                        </div>
                      </div>
                      <label className="form__label">
                        Conferma Password
                        <input
                          type="password"
                          name="password2"
                          placeholder=""
                          disabled={isSubmitting}
                          maxLength={320}
                          className="form__input"
                          {...register("password2", {
                            required: "Conferma password richiesta",
                            validate: (value, values) =>
                              value === values.password ||
                              "La password non è uguale",
                          })}
                        />
                      </label>
                      {errors.password2 ? (
                        <div className="form__error">
                          {errors.password2.message}
                        </div>
                      ) : null}
                      {errors.server ? (
                        <div className="my-3 form__err-message">
                          <p>{errors.server.message}</p>
                        </div>
                      ) : null}
                      <div className="text-center mt-3">
                        <button
                          type="submit"
                          className="btn btn--link btn--small"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <div className="d-flex align-items-center">
                              <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Caricamento...
                            </div>
                          ) : (
                            "Cambia password"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordDimenticata;
