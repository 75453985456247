import { memo, useEffect, useRef } from "react";
import CookieBot from "react-cookiebot";

export const domainGroupId = import.meta.env.VITE_COOKIEBOT_ID;

const CookieBotComponent = memo(() => (
  <CookieBot domainGroupId={domainGroupId} />
));

export const CookieDeclaration = memo(() => {
  const ref = useRef();

  useEffect(() => {
    const existingScript = document.getElementById("CookieDeclaration");
    if (existingScript) {
      existingScript.remove();
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "CookieDeclaration";
    script.src = `https://consent.cookiebot.com/${domainGroupId}/cd.js`;
    script.async = true;
    ref.current.appendChild(script);
  }, []);

  return <div ref={ref}></div>;
});

export default CookieBotComponent;
