import Icon from "../components/icons";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/it";

const CalendarSearch = ({ data, onChange, value }) => {
  const [singleDay, setSingleDay] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("dt_r")) {
      setSingleDay(true);
    }
  }, [searchParams]);

  return (
    <div
      className={classNames(
        "d-flex justify-content-center calendar",
        !singleDay && "calendar--disabled"
      )}
    >
      <div>
        <div className="calendar__month d-flex flex-column flex-lg-row gap-1 mt-3">
          <div className="d-flex flex-column mb-3">
            <div>Ottobre</div>
            <div className="d-flex flex-wrap flex-row gap-1">
              {data.ottobre.map((item) => {
                const dayNumber = dayjs(item.daycode, "YYYYMMDD").format("D");
                const dayName = dayjs(item.daycode, "YYYYMMDD")
                  .locale("it")
                  .format("ddd");
                return (
                  <div
                    key={item.daycode}
                    className={classNames(
                      "calendar__day",
                      value === item.searchcode &&
                        singleDay &&
                        "calendar__day--active"
                    )}
                    title={dayjs(item.daycode, "YYYYMMDD")
                      .locale("it")
                      .format("dddd, D MMMM")}
                    onClick={(e) => {
                      singleDay && onChange(item.searchcode);
                    }}
                  >
                    {" "}
                    <span className="calendar__number">{dayNumber}</span>
                    <span
                      className={classNames(
                        "calendar__name",
                        (dayName === "dom" || dayName === "sab") &&
                          "calendar__name--weekend"
                      )}
                    >
                      {dayName}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          {data?.novembre && (
            <div className="d-flex flex-column mb-3">
              <div>Novembre</div>
              <div className="d-flex flex-wrap flex-row gap-1">
                {data.novembre.map((item) => {
                  const dayNumber = dayjs(item.daycode, "YYYYMMDD").format("D");
                  const dayName = dayjs(item.daycode, "YYYYMMDD")
                    .locale("it")
                    .format("ddd");
                  return (
                    <div
                      key={item.daycode}
                      className={classNames(
                        "calendar__day",
                        value === item.searchcode &&
                          singleDay &&
                          "calendar__day--active"
                      )}
                      title={dayjs(item.daycode, "YYYYMMDD")
                        .locale("it")
                        .format("dddd, D MMMM")}
                      onClick={(e) => {
                        singleDay && onChange(item.searchcode);
                      }}
                    >
                      {" "}
                      <span className="calendar__number"> {dayNumber}</span>
                      <span
                        className={classNames(
                          "calendar__name",
                          (dayName === "dom" ||
                            dayName === "sab" ||
                            dayNumber === "1") &&
                            "calendar__name--weekend"
                        )}
                      >
                        {dayName}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="float-none float-lg-end">
          <button
            type="button"
            className="btn-all-day"
            title={singleDay ? "Scegli un giorno" : "Visualizza tutti i giorni"}
            onClick={() => {
              onChange("");
              setSingleDay(!singleDay);
            }}
          >
            <span className="btn-all-day-label">
              {singleDay ? "Scegli un giorno" : "Visualizza tutti i giorni"}
            </span>
            &#160;
            <Icon name="check" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarSearch;
