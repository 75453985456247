import Modal from "react-overlays/Modal";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import "dayjs/locale/it";
import Icon from "../components/icons";
import { useAddBooking } from "../hooks/useQueryCart";
import UserContext from "../context/user";
import classNames from "classnames";
import { useNavigazione } from "../context/navigazione";
import { Link } from "react-router-dom";

const renderBackdrop = () => <div className="modal__external"></div>;

const BookingModal = ({ show, daycode, slot, premium, hide, quantity }) => {
  const day = dayjs(daycode, "YYYYMMDD").locale("it");
  //const npart = Array.from(Array(quantity).keys());
  const npart = Array.from(Array(1).keys());
  const { userProfile } = useContext(UserContext);
  const addBooking = useAddBooking();
  const { getPathByTemplate } = useNavigazione();
  const [placement, setPlacement] = useState("");
  const linkCarrello = getPathByTemplate("carrello");
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    // reset,
    // formState: { errors, isValid, isSubmitted, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      partecipanti: [
        {
          nome: userProfile.attributes?.name,
          cognome: userProfile.attributes?.family_name,
          telefono: userProfile.attributes?.["custom:mobile"],
        },
      ],
    },
  });

  const onSubmit = async (data) => {
    setSubmitting(true);
    const payload = {
      id_slot: slot.id_slot,
      quant: Number(quantity),
      ...data,
    };
    try {
      await addBooking.mutateAsync(payload);
      setSubmitting(false);
    } catch (error) {
      if (error?.code === "F6") {
        setPlacement("Disponibilità esaurita");
      } else if (error?.code === "F5") {
        setPlacement("Prenotazione già presente");
      } else if (error?.code === "F9" || error?.code === "F10") {
        setPlacement(
          "Prenotazione non consentita, condivide data e ora con altra prenotazione "
        );
      } else if (error?.code === "F8") {
        setPlacement("Posto già prenotato in un precedente ordine");
      } else {
        setPlacement("Servizio attualmente non disponibile");
      }
    }
  };

  const onClose = () => {
    addBooking.reset();
    hide();
  };

  return (
    <Modal
      className="modal__internal d-flex flex-column"
      show={show}
      renderBackdrop={renderBackdrop}
    >
      <>
        <div className="modal__header">
          <h3>Partecipanti</h3>
        </div>
        <div className="d-flex flex-column flex-fill modal__body  min-h-0">
          <p>
            Ti chiediamo di inserire i tuoi dati (nome, cognome, telefono) per
            avvertiti in caso di variazioni relative all'evento prenotato. I
            dati verranno cancellati dai nostri sistemi al termine della
            manifestazione.
          </p>
          <p>
            Giorno della prenotazione:{" "}
            <strong>{day.format("dddd D MMMM")}</strong>
            <br />
            Orario: <strong>{slot.orario}</strong>
            <br />
            Numero posti: <strong>{quantity}</strong>
          </p>
          <form
            name="prenota"
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex flex-column flex-fill my-3 min-h-0"
          >
            <div className="flex-fill form modal__form modal__form--scrollable min-h-0">
              {npart.map((item, index) => (
                <div key={index} className="mb-3">
                  <div className="mb-2">
                    <strong>Contatto</strong>
                  </div>
                  <div className="d-flex flex-wrap gap-3">
                    <input
                      className="form__input"
                      placeholder="Nome"
                      type="text"
                      {...register(`partecipanti.${index}.nome`, {
                        required: true,
                      })}
                      readOnly={premium}
                    />
                    <input
                      className="form__input"
                      placeholder="Cognome"
                      type="text"
                      {...register(`partecipanti.${index}.cognome`, {
                        required: true,
                      })}
                      readOnly={premium}
                    />
                    <input
                      className={classNames("form__input")}
                      placeholder="Telefono"
                      type="text"
                      {...register(`partecipanti.${index}.telefono`, {
                        required: false,
                      })}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-between pt-3 gap-4">
              {addBooking.isSuccess && (
                <div>
                  <Icon name="success" /> Prenotazione aggiunta. Per confermare
                  le prenotazioni, anche se hai un Abb. Premium, vai su{" "}
                  <Link to={linkCarrello}>completa l'acquisto</Link>
                </div>
              )}
              {addBooking.isError && (
                <div>
                  <Icon name="alert" /> {placement}
                </div>
              )}
              {!addBooking.isSuccess && !addBooking.isError && (
                <button
                  className="btn btn--link"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}{" "}
                  Aggiungi
                </button>
              )}

              <button className="btn btn--link" type="button" onClick={onClose}>
                Chiudi
              </button>
            </div>
          </form>
        </div>
      </>
    </Modal>
  );
};

export default BookingModal;
