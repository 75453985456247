import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Seo from "../components/seo";
import { useSponsor } from "../hooks/useQueryCustom";
import PageContent from "../components/page-content";

const Sponsor = ({ page, breadcrumbs }) => {
  const { data: sponsor } = useSponsor();
  return (
    <>
      <Seo title={page.name} />
      <Header />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper mt-3 " id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h1>{page.name}</h1>
              <PageContent data={sponsor} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sponsor;
