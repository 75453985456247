import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Auth } from "@aws-amplify/auth";
import { HelmetProvider } from "react-helmet-async";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { UserProvider } from "./context/user";
import { NavigazioneProvider } from "./context/navigazione";
import useScrollToTop from "./hooks/useScrollToTop";
import AppRoutes from "./routes/app-routes";
import CookieBotComponent, { domainGroupId } from "./components/cookiebot";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const VITE_USERPOOL_ID = import.meta.env.VITE_USERPOOL_ID;
const VITE_USERPOOL_CLIENT_ID = import.meta.env.VITE_USERPOOL_CLIENT_ID;
const VITE_REGION = import.meta.env.VITE_REGION;
const RECAPTCHA_PUBLIC_KEY = import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY;
const VITE_MATOMO_URL = import.meta.env.VITE_MATOMO_URL;

const instance = VITE_MATOMO_URL
  ? createInstance({
      urlBase: "https://www.festivalscienza.online",
      siteId: "1",
      trackerUrl: VITE_MATOMO_URL,
      srcUrl: "https://cdn.matomo.cloud/festivalscienza.matomo.cloud/matomo.js",
      disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
      heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 15, // optional, default value: `15
      },
      linkTracking: true, // optional, default value: true
    })
  : null;

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000, // 30 min
    cacheTime: 8 * 60 * 60 * 1000, // 8h
  },
});

function App() {
  useScrollToTop();
  useEffect(() => {
    Auth.configure({
      userPoolId: VITE_USERPOOL_ID,
      userPoolWebClientId: VITE_USERPOOL_CLIENT_ID,
      region: VITE_REGION,
    });
  }, []);
  return (
    <MatomoProvider value={instance}>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <HelmetProvider>
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_PUBLIC_KEY}>
              <NavigazioneProvider>
                <div id="app-container" className={`app`}>
                  <AppRoutes />
                </div>
              </NavigazioneProvider>
              {domainGroupId && <CookieBotComponent />}
            </GoogleReCaptchaProvider>
          </HelmetProvider>
        </UserProvider>
      </QueryClientProvider>
    </MatomoProvider>
  );
}

export default App;
