import Header from "../components/header";
import Seo from "../components/seo";
import { CookieDeclaration, domainGroupId } from "../components/cookiebot";

const CookiePolicy = () => {
  return (
    <>
      <Seo title="Cookie policy" />
      <Header />
      <div role="main" className="main-wrapper mt-3 " id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Cookie Policy</h1>
              <p>
                <em>28 giugno 2022, versione in fase di revisione</em>
              </p>
              <ul>
                <li>
                  <a href="#Titolare_del_trattamento">
                    Titolare del trattamento
                  </a>
                </li>
                <li>
                  <a href="#Cosa_sono_i_cookie">Cosa sono i cookie</a>
                </li>
                <li>
                  <a href="#Tipologia_dei_dati_incorporati_nei_cookie">
                    Tipologia dei dati incorporati nei cookie
                  </a>
                </li>
                <li>
                  <a href="#Cookie_tecnici">Cookie tecnici</a>
                </li>
                <li>
                  <a href="#Cookie_non_tecnici_o_di_tracciamento">
                    Cookie non tecnici o di tracciamento
                  </a>
                </li>
                <li>
                  <a href="#Durata">Durata dei cookie</a>
                </li>
                <li>
                  <a href="#Le_finalit__dei_cookie_sul_nostro_sito">
                    Le finalità dei cookie sul nostro sito
                  </a>
                </li>
                <li>
                  <a href="#Trasferimento_extra_UE">Trasferimento extra UE</a>
                </li>
                <li>
                  <a href="#I_link_verso_i_siti_di_terze_parti">
                    I link verso i siti di terze parti
                  </a>
                </li>
                <li>
                  <a href="#Come_disabilitare_i_cookie">
                    Come disabilitare i cookie
                  </a>
                </li>
                <li>
                  <a href="#I_diritti_degli_interessati">
                    Diritti degli interessati
                  </a>
                </li>
                <li>
                  <a href="#Diritto_di_reclamo">Diritto di reclamo</a>
                </li>
              </ul>
              <div className="mt-3" id="Titolare_del_trattamento">
                <strong>Titolare del trattamento </strong>
              </div>
              L’Associazione Festival della Scienza (in seguito Festival), con
              sede legale in Genova, 16152, Corso Perrone 24, Tel. 010.6598795,
              e-mail:{" "}
              <a href="mailto:info@festivalscienza.it">
                info@festivalscienza.it
              </a>
              ,{" "}
              <a href="mailto:festivalscienza@pec.festivalscienza.it">
                festivalscienza@pec.festivalscienza.it
              </a>
              , e-mail dedicata alla privacy:{" "}
              <a href="mailto:privacy@festivalscienza.it">
                privacy@festivalscienza.it
              </a>
              ; La navigazione sulle pagine di questo sito non comporta alcun
              obbligo di comunicazione dei dati personali.Tuttavia, durante la
              navigazione alcuni dati che vi riguardano vengono raccolti
              automaticamente tramite i cookie. La presente policy vi fornisce
              le informazioni sul trattamento dei vostri dati personali quando
              navigate sul nostro sito.
              <div className="mt-3" id="Cosa_sono_i_cookie">
                <strong>Cosa sono i cookie</strong>
              </div>
              I Cookie sono piccole stringhe di testo che trasmettono
              informazioni senza le quali alcune funzionalità del sito non
              potrebbero esistere. Le pagine web sono, infatti, prive di memoria
              e non possono passare facilmente informazioni tra di loro, motivo
              per cui ogni pagina del sito che state visitando inserisce i
              cookie sul vostro computer e li riceve indietro. I cookie vengono
              registrati nella memoria del vostro browser con indicazione del
              nome del server da cui il cookie è stato inviato, la sua scadenza
              e un valore, che è solitamente un numero unico generato a caso dal
              computer. Il server del sito web che trasferisce il cookie
              utilizza questo numero per riconoscervi quando tornate a visitare
              il sito o navigate da una pagina all’altra. Solo il server che ha
              inviato un cookie può leggere e quindi utilizzare quel cookie.
              Quando i cookie sono impostati dal sito che l'utente sta visitando
              (cd. Publisher) si parla di "cookie di prima parte", mentre i
              cookie inviati da un server non riconducibile al sito che state
              visitando sono detti "cookie di terze parti". Le terze parti
              generalmente forniscono contenuti e i servizi pubblicitari o
              permettono analisi del sito.
              <div
                className="mt-3"
                id="Tipologia_dei_dati_incorporati_nei_cookie"
              >
                <strong>Tipologia dei dati incorporati nei cookie</strong>
              </div>
              I cookie possono includere dati quali l’indirizzo IP o il nome a
              dominio del computer, l’indirizzo in notazione URI, l’orario della
              visita o di una richiesta, il metodo utilizzato nell’inoltrare la
              richiesta al server, la dimensione del file ottenuto in risposta,
              il codice numerico indicante lo stato della risposta dal server
              (buon fine, errore, ecc.) il paese di provenienza, le
              caratteristiche del browser e del sistema operativo, la permanenza
              e l’itinerario seguito durante la navigazione all’interno del
              sito, preferenze di lingua e altre. Tali dati generalmente non
              consentono la vostra identificazione diretta, ma potrebbero
              rendervi identificabili attraverso la combinazione con altri.
              <div className="mt-3" id="Cookie_tecnici">
                <strong>Cookie tecnici</strong>
              </div>
              I cookie tecnici sono necessari per garantire la funzionalità del
              sito e per rendere migliore la vostra esperienza di navigazione.
              Vengono utilizzati al fine di “effettuare la trasmissione di una
              comunicazione su una rete di comunicazione elettronica, o nella
              misura strettamente necessaria al fornitore di un servizio della
              società dell'informazione esplicitamente richiesto dal contraente
              o dall'utente a erogare tale servizio” (cfr. art. 122 del Codice
              Privacy). I cookie tecnici forniscono una sorta di memoria alle
              pagine web, ad esempio, consentono di effettuare il login su una
              pagina, di spostarsi su altre pagine e di rimanere connessi, di
              impostare le preferenze per la visualizzazione di una pagina e di
              ricordarle la prossima volta che vi ritornate. Anche i cookie
              analitici sono equiparabili ai cookie tecnici quando sono
              utilizzati unicamente per produrre statistiche aggregate in
              relazione al sito del Titolare e se vengono installati da terze
              parti, l’indirizzo IP dell’utente deve essere parzialmente
              mascherato, e i cookie stessi non devono essere utilizzati per
              ulteriori combinazioni con altri dati o trasmessi ad ulteriori
              terzi.
              <div className="mt-3" id="Cookie_non_tecnici_o_di_tracciamento">
                <strong>Cookie non tecnici o di tracciamento</strong>
              </div>
              Questi cookie non sono necessari per il funzionamento del sito e
              non possono essere utilizzati senza il previo consenso del
              visitatore. Appartengono a questa categoria:- i cookie di
              profilazione consentono ad un Publisher di tracciare la
              navigazione in rete dell’utente e di classificarlo in gruppi di
              profili omogenei sulla base di alcuni suoi schemi comportamentali
              (es. i gusti, le abitudini, le scelte effettuate etc.) in modo da
              poter fornire un servizio oltre il necessario e inviare i messaggi
              pubblicitari in linea con le preferenze rilevate.- i cookie
              analitici privi di accorgimenti richiesti per poter essere
              equiparabili ai cookie tecnici utilizzati singolarmente o in
              combinazione con altri elementi riguardanti un visitatore (ad
              esempio, file o statistiche di visite ad altri siti) che possono
              renderlo identificabile e tracciabile.Vi informiamo che i cookie
              sono il mezzo più comune, ma non l’unico, per il tracciamento
              online e che molti siti web utilizzano “web beacon” o “pixel” per
              monitorare il comportamento dell'utente che visita il sito. Si
              tratta di piccole immagini trasparenti che forniscono statistiche
              per scopi simili a quelli dei cookie. Ad esempio, quando il
              browser si connette a un sito con il web beacon e richiede di
              scaricare un’immagine, insieme a questa richiesta possono essere
              inviati dettagli come l'indirizzo IP, il tipo di browser, l'ora di
              accesso o i cookie precedentemente impostati. Spesso vengono
              utilizzati insieme ai cookie, anche se non vengono memorizzati sul
              vostro computer nello stesso modo. Di conseguenza, se si
              disabilitano i cookie, i web beacon avranno una funzionalità
              limitata.
              <div className="mt-3" id="Durata">
                <strong>Durata</strong>
              </div>
              I cookie possono avere caratteristiche diverse sotto il profilo
              temporale e pertanto essere considerati in base alla loro durata:
              i cookie di sessione vengono memorizzati sul vostro browser per il
              solo tempo della visita di una pagina del sito. Altri cookie
              possono persistere su vostro browser per un determinato periodo di
              tempo (cosiddetti cookie persistenti)
              <div className="mt-3" id="Le_finalit__dei_cookie_sul_nostro_sito">
                <strong>Le finalità dei cookie sul nostro sito </strong>
              </div>
              Utilizziamo i cookie per:salvare le preferenze del visitatore in
              modo da ricordare le scelte che effettuate (come il vostro
              username, la vostra lingua o l’area geografica in cui vivete) al
              fine di fornirvi le funzionalità richieste, ottimizzarne
              l’utilizzo e garantire una migliore distribuzione del traffico. I
              cookie di sessione in particolare sono indispensabili per poter
              effettuare e mantenere la navigazione nel sito e l’utilizzo delle
              sue funzionalità nell’ambito della sessione, rendere operativo il
              nostro sito – i cookie consentono al sito fornirvi le funzionalità
              come, ad esempio, la visualizzazione di un video -&nbsp;effettuare
              analisi statistica – su come interagite con il nostro sito. Vi
              informiamo che stiamo provvedendo a sostituire i Google analytics
              con i Matomo analitycs. Siete comunque liberi di rifiutare questi
              cookie, attraverso il banner sui cookie.
              <div className="mt-3" id="Trasferimento_extra_UE">
                <strong>Trasferimento extra UE</strong>
              </div>
              alcuni dati sono trasferiti nei server in USA tramite google
              analitycs. Vi informiamo che in seguito al recente Provvedimento
              del Garante per la Privacy che ha sancito illiceità di detto
              trasferimento, stiamo provvedendo a sostituire i Google analytics
              con i Matomo analitycs, cookie conformi al GDPR il cui utilizzo
              non comporta alcun trasferimento dei vostri dati fuori dallo
              spazio SEE.
              <div className="mt-3" id="I_link_verso_i_siti_di_terze_parti">
                <strong>I link verso i siti di terze parti </strong>
              </div>
              Sul sito sono presenti elementi, quali, immagini, suoni, mappe o
              specifici link a pagine web di altri domini che risiedono su
              server diversi da quello che ospita il sito. Vengono aggiunti per
              ottenere le funzionalità ulteriori. Ad esempio, se cliccate su una
              icona social o su altri link presenti su questo sito, sarete
              automaticamente rinviati alla piattaforma interessata. Noi non
              abbiamo il controllo sulle politiche in materia dei cookie di
              queste piattaforme. Le informazioni successivamente acquisite sono
              accessibili esclusivamente al proprietario della piattaforma alla
              quale effettuate accesso in tal modo, che tratterà i vostri dati
              in conformità alla propria privacy policy. Se non visualizzate
              detti contenuti non viene installato alcun cookie di terza parte
              sul vostro dispositivo.
              <div className="mt-3" id="Come_disabilitare_i_cookie">
                <strong>Come disabilitare i cookie</strong>
              </div>{" "}
              Oltre a poter negare il Vostro consenso all’uso dei cookie non
              necessari, potete anche rimuovere direttamente i cookie archiviati
              all’interno del vostro dispositivo. Sebbene i browser generalmente
              accettano automaticamente i cookie, per ognuno di essi è infatti
              possibile modificare le impostazioni al fine di limitare o
              impedire l’uso di cookie. Potete ad esempio, cliccare sull’icona
              del lucchetto posto a destra dell’indirizzo URL del nostro sito
              per vedere quali e quanti cookie sono in uso mentre lo visitate,
              bloccare tutti o alcuni o rimuoverli.Potete inoltre impostare il
              vostro browser in modo da impedire il tracciamento o installazione
              di alcuni o di tutti i cookie durante la navigazione su qualsiasi
              sito che visitate. Ricordiamo, tuttavia, che la disabilitazione o
              la cancellazione dei cookie tecnici potrebbe impedire o
              compromettere la fruizione di alcune aree o servizi del sito e che
              le impostazioni devono essere modificate separatamente in ogni
              browser e computer utilizzato, seguendo le istruzioni sotto
              indicate: Crome: cliccare in alto a destra della finestra, quindi,
              “impostazioni” – “privacy e sicurezza” – “cookie e altri dati dei
              siti” – “blocca i cookie”; Edge: cliccare in alto a destra della
              finestra – quindi, “impostazioni” - “privacy, ricerca e servizi” -
              “prevenzione dal rilevamento”; Opera: cliccare su icona
              “impostazioni” – poi “impostazioni di base” – “blocca tracker e
              blocca gli annunci pubblicitari”; Firefox: cliccare in alto a
              destra della finestra su “apri menù” – quindi “opzioni” – “privacy
              e sicurezza” – “protezione anti tracciamento”; Safari: clicca
              sull’app “safari” - quindi “preferenze” – “privacy”
              <p>
                Per maggiori informazioni sulla gestione dei cookie, riportiamo
                anche i link relativi ai browser più utilizzati: Safari™:{" "}
                <a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT">
                  http://support.apple.com/kb/HT1677?viewlocale=it_IT{" "}
                </a>
                <br />
                Chrome™:{" "}
                <a href="https://support.google.com/chrome/answer/95647?hl=it">
                  https://support.google.com/chrome/answer/95647?hl=it{" "}
                </a>
                <br />
                Firefox™:{" "}
                <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">
                  https://support.mozilla.org/it/kb/Gestione%20dei%20cookie{" "}
                </a>
                <br />
                Opera™:{" "}
                <a href="http://help.opera.com/Windows/10.20/it/cookies.html">
                  http://help.opera.com/Windows/10.20/it/cookies.html{" "}
                </a>
                <br />
                Edge:{" "}
                <a href="https://microsoftedgewelcome.microsoft.com/it-it/privacy?source=settings">
                  https://microsoftedgewelcome.microsoft.com/it-it/privacy?source=settings
                </a>
                <br />
              </p>
              <p>
                Per ulteriori informazioni su come visualizzare i cookie che
                sono stati impostati sul vostro dispositivo, su come gestirli ed
                eliminarli, visitate i seguenti siti:{" "}
                <a href="https://www.youronlinechoices.com/it/le-tue-scelte">
                  https://www.youronlinechoices.com/it/le-tue-scelte
                </a>{" "}
                e{" "}
                <a href="http://www.allaboutcookies.org">
                  www.allaboutcookies.org
                </a>
              </p>
              .
              <div className="mt-3" id="I_diritti_degli_interessati">
                <strong>I diritti degli interessati</strong>
              </div>
              - ottenere informazioni sul trattamento in forma concisa,
              trasparente e intelligibile in un linguaggio semplice e chiaro e
              tin particolare, del tipo di trattamento in atto, della categoria
              dei dati sottoposti al trattamento e delle finalità del
              trattamento;- ottenere conferma dell’esistenza dei propri dati
              personali presso il Titolare e accedere agli stessi per averli a
              disposizione in forma intelligibile entro un mese dalla
              richiesta;- conoscere la fonte da cui hanno origine;- ottenere la
              portabilità, in un formato strutturato, di uso comune e leggibile
              da un dispositivo automatico, dei vostri dati personali trattati
              con mezzi automatizzati che avete fornito direttamente con mezzi
              digitali e a trasmettere tali dati ad un altro Titolare del
              trattamento; - limitare il trattamento in determinati casi;-
              opporsi al trattamento;- chiedere la rettifica e/o l’integrazione
              dei propri dati personali;- revocare il consenso in qualsiasi
              momento senza pregiudizio per la liceità del trattamento
              effettuato prima della revoca;- ottenere la cancellazione dei dati
              quando le finalità del trattamento vengono meno, quando il
              trattamento è illecito, quando la cancellazione è prevista dalla
              legge per adempiere ad un obbligo legale;- ottenere informazioni
              sull’esistenza del trattamento automatizzato dei dati e pretendere
              che nei Suoi riguardi non siano prese decisioni basate su detto
              trattamento compresa la profilazione, senza la possibilità di un
              intervento umano. Per esercitare questi diritti potete contattarci
              all’indirizzo e-mail:{" "}
              <a href="mailto:privacy@festivalscienza.it">
                privacy@festivalscienza.it
              </a>
              . Consultate apposito modulo di accesso ai dati pubblicato sul
              nostro sito{" "}
              <a href="http://www.festivaldellascienza.it">
                www.festivaldellascienza.it
              </a>{" "}
              e seguire le istruzioni
              <div className="mt-3" id="Diritto_di_reclamo">
                <strong>Diritto di reclamo </strong>
              </div>
              in caso di trattamento illecito o di una violazione dei vostri
              diritti potete proporre reclamo all’Autorità Garante. Per
              esercitare il diritto di reclamo è necessario accedere al sito
              istituzionale del Garante per la privacy{" "}
              <a href="http://www.garanteprivacy.it">www.garanteprivacy.it</a> e
              compilare un apposito modulo ivi pubblicato. In alternativa,
              potete adire alle competenti Autorità giudiziali.
              {domainGroupId && <CookieDeclaration />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CookiePolicy;
