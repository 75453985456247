import classNames from "classnames";
import icons from "../assets/icons.svg";

const Icon = ({ name, className, ...other }) => (
  <svg className={classNames("icon", className)} {...other}>
    <use href={`${icons}#${name}`} />
  </svg>
);

export default Icon;
