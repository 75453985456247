import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Seo from "../components/seo";
import { useEdizioni } from "../hooks/useQueryCustom";

const EdizioniPrecedenti = ({ page, breadcrumbs }) => {
  const { data: edizioni } = useEdizioni();
  console.log(edizioni);
  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper  mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h1>{page.name}</h1>
            </div>
          </div>
          <div className="row">
            {edizioni?.map((item) => (
              <div key={item.titolo} className="col-sm-6 col-lg-4 my-4">
                <div className="edition d-flex flex-column h-100">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.link}
                    title={item.titolo}
                  >
                    <img
                      loading="lazy"
                      src={item.immagine.url}
                      alt={item.immagine.title}
                    />
                  </a>
                  <h2 className="edition__title">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={item.link}
                      title={item.titolo}
                    >
                      {item.titolo}
                    </a>
                  </h2>
                  <div className="edition__text flex-grow-1">{item.testo}</div>
                  <div className="edition__num w-100  d-flex flex-row">
                    <div className="d-flex flex-fill flex-column text-center">
                      <span className="edition__num-value">
                        {item.visite || "N.D."}
                      </span>
                      <span className="edition__num-label">Visite</span>
                    </div>
                    <div className="d-flex flex-fill flex-column text-center">
                      <span className="edition__num-value">{item.eventi}</span>
                      <span className="edition__num-label">Eventi</span>
                    </div>
                    <div className="d-flex flex-fill flex-column text-center">
                      <span className="edition__num-value">
                        {item.relatori}
                      </span>
                      <span className="edition__num-label">Relatori</span>
                    </div>
                    <div className="d-flex flex-fill flex-column text-center">
                      <span className="edition__num-value">
                        {item.location || "Online"}
                      </span>
                      <span className="edition__num-label">Location</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default EdizioniPrecedenti;
