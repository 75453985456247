import { memo } from "react";
import { Link } from "react-router-dom";
import Icon from "../components/icons";
import { getTipoEvento, getURLImage } from "../utils/utility";
import Skeleton from "react-loading-skeleton";
import { useNavigazione } from "../context/navigazione";
import classNames from "classnames";

const EventCard = ({
  data,
  enableFavourite,
  favourite,
  toggleFavouriteAction,
}) => {
  const { getPathByTemplate } = useNavigazione();
  const link = getPathByTemplate("programma");
  return (
    <div className="event-card mb-5">
      <div className="event-card__img-cat">
        <Link
          className="event-card__link"
          title={data.id}
          to={`${link}/${data.slug}`}
        >
          {"placeholder" in data ? (
            <Skeleton width="100%" height={240} />
          ) : (
            <img
              className="event-card__img"
              alt={data.titolo}
              loading="lazy"
              src={getURLImage(data)}
              width="100%"
            />
          )}
        </Link>
        {data?.genere && (
          <span className="event-card__category">
            {getTipoEvento(data.genere)}
          </span>
        )}
        {enableFavourite && (
          <div
            className={classNames(
              "event-card__favourite",
              favourite && "event-card__favourite--selected"
            )}
            onClick={() => toggleFavouriteAction(data.id, !favourite)}
          >
            <Icon name={favourite ? "heart-fill" : "heart"} />
          </div>
        )}
      </div>
      <div className="event-card__content">
        <div
          className={classNames(
            data.sezione_fe === "3" && "d-flex justify-content-between gap-2"
          )}
        >
          <h2 className="event-card__title ">
            <Link
              className="event-card__link"
              title={data.titolo}
              to={`${link}/${data.slug}`}
            >
              {"placeholder" in data ? <Skeleton /> : data.titolo}
            </Link>
          </h2>
          {data.sezione_fe === "3" && (
            <div className="event-card__extra align-self-start">Extra</div>
          )}
        </div>

        <div className="event-card__subject">
          {"placeholder" in data ? <Skeleton /> : data.sottotitolo}
        </div>
        <div className="event-card__location">
          {data?.location && <Icon name="location" />}
          {"placeholder" in data ? <Skeleton /> : data.location}
        </div>
        <div className="event-card__date">
          {data?.orario && <Icon name="calendar" />}
          <span>{"placeholder" in data ? <Skeleton /> : data.orario}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(EventCard);
