const cognitoErrorMessages = {
  NotAuthorizedException: "Username o password errati",
  UsernameExistsException: "Questo indirizzo email risulta già registrato.",
  // CodeMismatchException: 'Codice di conferma errato',
  // ExpiredCodeException: 'Codice di conferma scaduto',
  CodeMismatchException: "Link non valido o scaduto",
  ExpiredCodeException: "Link non valido o scaduto",
  UserNotConfirmedException:
    "L'indirizzo email non è stato validato. Controlla la tua casella di posta e segui le istruzioni per confermare l'account.",
  InvalidPasswordException:
    "Formato password non corretto. La password deve contenere almeno una maiuscola, una minuscola, un numero, un simbolo e almeno 8 caratteri in totale",
  LimitExceededException: "Troppi tentativi, aspetta prima di riprovare",
};

export const getErrorMessage = (err) => {
  const code = err?.code || err?.message;
  return (code && cognitoErrorMessages[code]) || "Si è verificato un errore";
};
