import ContentfulRichText from "../components/contentful-rich-text";
import FasciaArticoli from "./fascia-articoli";
import FasciaDidattica from "./fascia-didattica";
import FasciaPersone from "./fascia-persone";
import FasciaSponsor from "./fascia-sponsor";

const getComponent = (component) =>
  ({
    Articolo: (
      <ContentfulRichText id={component.sys.id} key={component.sys.id} />
    ),
    FasciaArticoli: (
      <FasciaArticoli id={component.sys.id} key={component.sys.id} />
    ),
    FasciaDidattica: (
      <FasciaDidattica id={component.sys.id} key={component.sys.id} />
    ),
    FasciaPersone: (
      <FasciaPersone id={component.sys.id} key={component.sys.id} />
    ),
    FasciaSponsor: (
      <FasciaSponsor
        className="sponsor--small"
        data={component}
        key={component.sys.id}
      />
    ),
  }[component.__typename]);

const PageContent = ({ data, rientro }) => {
  // console.log(data?.articoliCollection?.items);
  return (
    <>
      {data?.testo && (
        <ContentfulRichText data={data.testo} rientro={rientro} />
      )}

      {data?.articoliCollection?.items?.map((component) =>
        getComponent(component)
      )}
    </>
  );
};
export default PageContent;
