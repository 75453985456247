import { useForm, Controller } from "react-hook-form";
import { useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { usePage } from "../hooks/useQueryCustom";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Steps from "../components/steps";
import CustomSelect from "../components/custom-select";
import Icon from "../components/icons";
import ErrorMessage from "../components/error-message";

import {
  getTicketOptions,
  getTicketDay,
  isValidDay,
  getTicketDesc,
  getLabelText,
} from "../utils/utility";
import UserContext from "../context/user";
import { MAX_TICKET } from "../utils/constants";
import { useAddTicket, useCart, useRemoveTicket } from "../hooks/useQueryCart";
import PageContent from "../components/page-content";
import { useNavigazione } from "../context/navigazione";

const Biglietti = ({ page, breadcrumbs }) => {
  const timelineSection = useRef();
  const { isAmico } = useContext(UserContext);
  const addTicket = useAddTicket();
  const removeTicket = useRemoveTicket();
  const { data: cart, isLoading } = useCart();
  const { data } = usePage(page.link.slice(1));
  const { getPathByTemplate } = useNavigazione();
  const linkProgramma = getPathByTemplate("programma");
  const linkCompletaAcquisto = getPathByTemplate("carrello");

  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    setError,
    getValues,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { tck: "abb" },
  });
  const watchTck = watch("tck");

  const onSubmit = async (values) => {
    const payload = values;
    const tck_code_arr = [];
    const num = getValues("num");
    const num_ridraga = getValues("num_ridraga");
    if (values.tck === "premium") {
      payload.num = 1;
      payload.num_ridraga = "";
      payload.tck_code = "A-P-I2:1";
    } else if (values.tck === "giornaliero") {
      payload.tck = getValues("tck-day");
      payload["tck-day-serale"] = "";
      if (num && parseInt(num) > 0) {
        tck_code_arr.push(`B-I-I1:${num}`);
      }
      if (num_ridraga && parseInt(num_ridraga) > 0) {
        tck_code_arr.push(`B-R-RR:${num_ridraga}`);
      }
      payload.tck_code = tck_code_arr.join(",");
    } else if (values.tck === "serale") {
      payload.num = "";
      payload["tck-day"] = "";
      payload.tck_code = `B-S-RR:${num_ridraga}`;
      payload.tck = getValues("tck-day-serale");
    } else if (values.tck === "abb") {
      if (num && parseInt(num) > 0) {
        tck_code_arr.push(`A-I-I1:${num}`);
      }
      if (num_ridraga && parseInt(num_ridraga) > 0) {
        tck_code_arr.push(`A-SR-RR:${num_ridraga}`);
      }
      payload.tck_code = tck_code_arr.join(",");
    } else if (values.tck === "pack-abb") {
      const pack_abb = getValues("pack-abb");
      payload.bundle = pack_abb;
      payload.num = 1;
      payload.tck = "abb";
      if (pack_abb === "abb2+1") {
        payload.tck_code = "B-I-I1:1,B-R-RR:2";
        payload.num_ridraga = 2;
      } else {
        payload.tck_code = "B-I-I1:1,B-R-RR:3";
        payload.num_ridraga = 3;
      }
    } else if (values.tck === "pack-day") {
      const pack_day = getValues("pack-tck");
      const pack_tck_day = getValues("pack-tck-day");
      payload.bundle = pack_day;
      payload.num = 1;
      payload.tck = pack_tck_day;
      if (pack_day === "day2+1") {
        payload.tck_code = "A-I-I1:1,A-R-RR:2";
        payload.num_ridraga = 2;
      } else {
        payload.tck_code = "A-I-I1:1,A-R-RR:3";
        payload.num_ridraga = 3;
      }
    }
    try {
      console.log("payload", payload);
      addTicket.mutate(payload);
      timelineSection.current.scrollIntoView();
    } catch (err) {
      console.error("Error: ", err);
      setError("server", { message: "Si è verificato un errore" });
    }
  };

  const onRemove = async (e) => {
    e.preventDefault();
    try {
      removeTicket.mutate(cart);
      reset();
      timelineSection.current.scrollIntoView();
    } catch (err) {
      console.error("Error: ", err);
      setError("server", { message: "Si è verificato un errore" });
    }
  };

  return (
    <>
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper  mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h1>Biglietti</h1>
            </div>
          </div>

          <div className="row" ref={timelineSection}>
            <div className="col-12">
              <Steps selected={1} />
            </div>
          </div>

          {cart?.gratuitapremium ? (
            <div className="cart bg-gray px-4 py-5 mb-4">
              <div className="text-center">
                {isAmico ? (
                  <p>
                    Le prenotazioni amico non hanno bisogno di conferma e sono
                    riepilogate nella pagina
                  </p>
                ) : (
                  <>
                    <p>
                      <strong>
                        Se acquisti un Abbonamento Premium, non potrai più
                        acquistare altri titoli di accesso.
                      </strong>
                    </p>
                    <p>
                      Una volta acquistato un Abbonamento Premium, potrai
                      aggiungere al carrello solo le prenotazioni gratuite, che
                      andranno sempre confermate cliccando su "Acquista" nella
                      sezione "Completa il tuo acquisto" nell'area riservata,
                      anche se l'importo che vedrai è di 0€.
                      <br />
                      Puoi aggiungere prenotazioni navigando il{" "}
                      <Link to={linkProgramma}>programma</Link>
                    </p>

                    <p>
                      Oppure puoi inserire i tuoi dati e{" "}
                      <Link to={linkCompletaAcquisto}>
                        completare l'acquisto
                      </Link>
                    </p>
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  {addTicket.isError ? (
                    <ErrorMessage error={addTicket.error} />
                  ) : null}
                </div>
              </div>
              {addTicket.isLoading || isLoading || removeTicket.isLoading ? (
                <div className="col-12">
                  <div className="text-center my-5">
                    <Icon name="spinner" className="icon--big icon--animated" />
                  </div>
                </div>
              ) : (
                <div className="row">
                  {cart?.tck ? (
                    <div className="col-12">
                      <div className="bg-gray p-4 mb-4">
                        <h2 className="h3 graphic">
                          <span className="graphic__label graphic__label--gray-light">
                            Biglietti nel carrello
                          </span>
                          <span className="graphic__line">&nbsp;</span>
                        </h2>
                        <div className="row d-none d-sm-flex">
                          <div className="col-xs-4 col-sm-4">
                            <strong>Tipologia</strong>
                          </div>
                          <div className="col-xs-4 col-sm-3">
                            <strong>Periodo</strong>
                          </div>
                          <div className="col-xs-4 col-sm-2">
                            <strong>Quantità</strong>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-4 col-sm-4">
                            {cart.bundle && "Insieme al Festival  - "}
                            {cart.tck === "abb" && "Abbonamento standard"}
                            {cart.tck === "premium" && "Abbonamento premium"}
                            {isValidDay(cart.tck) && "Giornaliero"}
                          </div>
                          <div className="col-xs-4 col-sm-3">
                            {cart.tck === "abb" &&
                              "tutti i giorni del Festival"}
                            {cart.tck === "premium" &&
                              "tutti i giorni del Festival"}
                            {isValidDay(cart.tck) && getTicketDesc(cart.tck)}
                          </div>
                          <div className="col-xs-4 col-sm-2">
                            {cart.bundle === "abb2+1" && "3 persone"}
                            {cart.bundle === "abb2+2" && "4 persone"}
                            {cart.bundle === "day2+1" && "3 persone"}
                            {cart.bundle === "day2+2" && "4 persone"}
                            {!cart.bundle && (
                              <>
                                Interi {cart.num}
                                {cart.num_ridraga > 0 &&
                                  ` - Ridottissimo o serale ${cart.num_ridraga}`}
                              </>
                            )}
                          </div>
                          <div className="col-12 col-sm-2">
                            <button
                              className="btn"
                              onClick={onRemove}
                              type="button"
                            >
                              Rimuovi
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <h2 className="h3 graphic mt-5">
                              <span className="graphic__label graphic__label--gray-light">
                                Cosa fare ora
                              </span>
                              <span className="graphic__line">&nbsp;</span>
                            </h2>
                            <p>
                              Puoi aggiungere prenotazioni navigando il{" "}
                              <Link to={linkProgramma}>programma</Link>
                            </p>
                            <p>
                              Inserire i tuoi dati e{" "}
                              <Link to={linkCompletaAcquisto}>
                                completare l'acquisto
                              </Link>
                            </p>
                            <p>
                              <Link onClick={(e) => onRemove(e)}>
                                Cambiare tipologia di biglietto
                              </Link>{" "}
                              , i biglietti attualmente presenti verranno
                              rimossi.
                              <br />
                              {cart.tck === "premium" &&
                                "Rimuovendo il biglietto premium anche le prenotazioni verranno rimosse"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-12 col-md-6">
                        <div className="bg-gray p-4 mb-4">
                          <form
                            name="biglietti"
                            className="form"
                            onSubmit={(e) => {
                              clearErrors("server");
                              handleSubmit(onSubmit)(e);
                            }}
                          >
                            <fieldset>
                              <legend>
                                <h2 className="h3 graphic">
                                  <span className="graphic__label graphic__label--gray-light">
                                    Tipologia
                                  </span>
                                  <span className="graphic__line">&nbsp;</span>
                                </h2>
                              </legend>
                              <div>
                                <label
                                  htmlFor="premium"
                                  className="form__radio-label"
                                >
                                  <input
                                    className="form__radio-input"
                                    type="radio"
                                    {...register("tck")}
                                    value="premium"
                                    id="premium"
                                  />
                                  <span className="form__custom-radio" />
                                  Abbonamento Premium
                                </label>
                                <div className="my-3 form__radio-desc">
                                  <p>{getLabelText(data?.label, "premium")}</p>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="abb"
                                  className="form__radio-label"
                                >
                                  <input
                                    className="form__radio-input"
                                    type="radio"
                                    {...register("tck")}
                                    value="abb"
                                    id="abb"
                                  />
                                  <span className="form__custom-radio" />
                                  Abbonamento Standard
                                </label>
                                <div className="my-3 form__radio-desc">
                                  <p>{getLabelText(data?.label, "standard")}</p>
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="giornaliero"
                                  className="form__radio-label"
                                >
                                  <input
                                    className="form__radio-input"
                                    type="radio"
                                    {...register("tck")}
                                    value="giornaliero"
                                    id="giornaliero"
                                  />
                                  <span className="form__custom-radio" />
                                  Biglietto Giornaliero
                                </label>

                                <div className="my-3 form__radio-desc">
                                  <Controller
                                    control={control}
                                    name="tck-day"
                                    rules={{
                                      required:
                                        getValues("tck") === "giornaliero"
                                          ? true
                                          : false,
                                    }}
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <CustomSelect
                                        isDisabled={
                                          getValues("tck") !== "giornaliero"
                                        }
                                        key="q-biglietti"
                                        placeholder="Seleziona il giorno"
                                        className="form__select form__select--small mb-3"
                                        options={getTicketDay()}
                                        onChange={(e) => {
                                          onChange(e?.value);
                                        }}
                                        onSubmit={() => void 0}
                                      />
                                    )}
                                  />
                                  <p>
                                    {getLabelText(data?.label, "giornaliero")}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="serale"
                                  className="form__radio-label"
                                >
                                  <input
                                    className="form__radio-input"
                                    type="radio"
                                    {...register("tck")}
                                    value="serale"
                                    id="serale"
                                  />
                                  <span className="form__custom-radio" />
                                  Biglietto Giornaliero Serale
                                </label>

                                <div className="my-3 form__radio-desc">
                                  <Controller
                                    control={control}
                                    name="tck-day-serale"
                                    rules={{
                                      required:
                                        getValues("tck") === "serale"
                                          ? true
                                          : false,
                                    }}
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <CustomSelect
                                        isDisabled={
                                          getValues("tck") !== "serale"
                                        }
                                        key="q-biglietti"
                                        placeholder="Seleziona il giorno"
                                        className="form__select form__select--small mb-3"
                                        options={getTicketDay()}
                                        onChange={(e) => {
                                          onChange(e?.value);
                                        }}
                                        onSubmit={() => void 0}
                                      />
                                    )}
                                  />
                                  <p>{getLabelText(data?.label, "serale")}</p>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="pack-day"
                                  className="form__radio-label"
                                >
                                  <input
                                    className="form__radio-input"
                                    type="radio"
                                    {...register("tck")}
                                    value="pack-day"
                                    id="pack-day"
                                  />
                                  <span className="form__custom-radio" />
                                  Insieme al Festival - Biglietti Giornalieri
                                </label>

                                <div className="my-3 form__radio-desc">
                                  <Controller
                                    control={control}
                                    name="pack-tck"
                                    rules={{
                                      required:
                                        getValues("tck") === "pack-day"
                                          ? true
                                          : false,
                                    }}
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <CustomSelect
                                        isDisabled={
                                          getValues("tck") !== "pack-day"
                                        }
                                        key="pack-tck-day-biglietti"
                                        placeholder="Seleziona il pacchetto"
                                        className="form__select form__select--small mb-3"
                                        options={[
                                          {
                                            value: "day2+1",
                                            label: "3 persone",
                                          },
                                          {
                                            value: "day2+2",
                                            label: "4 persone",
                                          },
                                        ]}
                                        onChange={(e) => {
                                          onChange(e?.value);
                                        }}
                                        onSubmit={() => void 0}
                                      />
                                    )}
                                  />
                                  <Controller
                                    control={control}
                                    name="pack-tck-day"
                                    rules={{
                                      required:
                                        getValues("tck") === "pack-day"
                                          ? true
                                          : false,
                                    }}
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <CustomSelect
                                        isDisabled={
                                          getValues("tck") !== "pack-day"
                                        }
                                        key="pack-tck-day-biglietti"
                                        placeholder="Seleziona il giorno"
                                        className="form__select form__select--small mb-3"
                                        options={getTicketDay()}
                                        onChange={(e) => {
                                          onChange(e?.value);
                                        }}
                                        onSubmit={() => void 0}
                                      />
                                    )}
                                  />

                                  <p>{getLabelText(data?.label, "pack-day")}</p>
                                </div>

                                <div>
                                  <label
                                    htmlFor="pack-abb"
                                    className="form__radio-label"
                                  >
                                    <input
                                      className="form__radio-input"
                                      type="radio"
                                      {...register("tck")}
                                      value="pack-abb"
                                      id="pack-abb"
                                    />
                                    <span className="form__custom-radio" />
                                    Insieme al Festival - Abbonamenti Standard
                                  </label>

                                  <div className="my-3 form__radio-desc">
                                    <Controller
                                      control={control}
                                      name="pack-abb"
                                      rules={{
                                        required:
                                          getValues("tck") === "pack-abb"
                                            ? true
                                            : false,
                                      }}
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                      }) => (
                                        <CustomSelect
                                          isDisabled={
                                            getValues("tck") !== "pack-abb"
                                          }
                                          key="qpa-biglietti"
                                          placeholder="Seleziona il pacchetto"
                                          className="form__select form__select--small mb-3"
                                          options={[
                                            {
                                              value: "abb2+1",
                                              label: "3 persone",
                                            },
                                            {
                                              value: "abb2+2",
                                              label: "4 persone",
                                            },
                                          ]}
                                          onChange={(e) => {
                                            onChange(e?.value);
                                          }}
                                          onSubmit={() => void 0}
                                        />
                                      )}
                                    />
                                    <p>
                                      {getLabelText(data?.label, "pack-abb")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <fieldset>
                              <legend>
                                <h2 className="h3 graphic">
                                  <span className="graphic__label graphic__label--gray-light">
                                    Quantità
                                  </span>
                                  <span className="graphic__line">&nbsp;</span>
                                </h2>
                              </legend>
                              {watchTck === "premium" ? (
                                <div>
                                  L'Abbonamento Premium è personale e puoi
                                  acquistarne solo uno per account.
                                </div>
                              ) : watchTck === "pack-abb" ||
                                watchTck === "pack-day" ? (
                                <div>
                                  Puoi acquistare un solo pacchetto promozionale
                                </div>
                              ) : (
                                <>
                                  {watchTck !== "serale" && (
                                    <div className="d-flex flex-column flex-lg-row  justify-content-between mb-4">
                                      <label>Intero</label>
                                      <Controller
                                        control={control}
                                        name="num"
                                        rules={{
                                          validate: (value, values) => {
                                            if (
                                              values.tck === "giornaliero" ||
                                              values.tck === "abb"
                                            ) {
                                              return (
                                                !!(
                                                  value || values.num_ridraga
                                                ) || "Quantità non specificata"
                                              );
                                            } else return true;
                                          },
                                        }}
                                        render={({
                                          field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            ref,
                                          },
                                        }) => (
                                          <CustomSelect
                                            key="q-biglietti"
                                            placeholder="Numero biglietti"
                                            className="form__select "
                                            options={getTicketOptions(
                                              MAX_TICKET
                                            )}
                                            onChange={(e) => {
                                              onChange(e?.value);
                                            }}
                                            onSubmit={() => void 0}
                                          />
                                        )}
                                      />
                                    </div>
                                  )}
                                  <div className="d-flex flex-column flex-lg-row  justify-content-between">
                                    <label>
                                      {watchTck === "serale" ? (
                                        "Giornaliero serale"
                                      ) : (
                                        <>
                                          Ridottissimo
                                          <br /> (dai 6 ai 18 anni, studenti
                                          universitari)
                                        </>
                                      )}
                                    </label>
                                    <Controller
                                      control={control}
                                      rules={{
                                        validate: (value, values) => {
                                          if (
                                            values.tck === "giornaliero" ||
                                            values.tck === "abb"
                                          ) {
                                            return (
                                              !!(value || values.num) ||
                                              "Quantità non specificata"
                                            );
                                          } else if (values.tck === "serale") {
                                            return (
                                              !!value ||
                                              "Quantità non specificata"
                                            );
                                          }
                                        },
                                      }}
                                      name="num_ridraga"
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                      }) => (
                                        <CustomSelect
                                          key="q-biglietti"
                                          placeholder="Numero biglietti"
                                          className="form__select  align-self-lg-center"
                                          options={getTicketOptions(MAX_TICKET)}
                                          onChange={(e) => {
                                            onChange(e?.value);
                                          }}
                                          onSubmit={() => void 0}
                                        />
                                      )}
                                    />
                                  </div>
                                </>
                              )}
                            </fieldset>
                            <p>{getLabelText(data?.label, "nota")}</p>
                            <button
                              className="btn btn--link btn--small align-self-lg-end mt-4"
                              title="Aggiung biglietti"
                              disabled={isSubmitting || !watchTck}
                            >
                              {isSubmitting && (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}{" "}
                              Aggiungi al carrello
                              <Icon className="ms-2" name="addcart" />
                            </button>
                            {(errors?.["tck-day-serale"] ||
                              errors?.["pack-tck-day"] ||
                              errors?.["tck-day"]) && (
                              <p className="form__error align-self-lg-end">
                                ATTENZIONE: giorno non selezionato
                              </p>
                            )}
                            {(errors?.["num"] || errors?.["num_ridraga"]) && (
                              <p className="form__error align-self-lg-end">
                                ATTENZIONE: quantità non specificata
                              </p>
                            )}
                            {(errors?.["pack-abb"] || errors?.["pack-tck"]) && (
                              <p className="form__error align-self-lg-end">
                                ATTENZIONE: pacchetto non selezionato
                              </p>
                            )}
                          </form>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        {data && <PageContent data={data} />}
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Biglietti;
