import Select, { components } from "react-select";
import Icon from "../components/icons";
import { forwardRef } from "react";

const Option = ({ children, ...props }) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  return (
    <components.ValueContainer {...props}>
      <div style={{ whiteSpace: "nowrap" }}>
        {getValue().reduce((a, b) => {
          return a.concat(b.label).concat(" ");
        }, "")}
      </div>
    </components.ValueContainer>
  );
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="angle-down" />
    </components.DropdownIndicator>
  );
};

const CustomSelect = forwardRef(
  ({ options, isMulti, onChange, value, customStyles, ...other }, ref) => {
    const customComponents = isMulti
      ? {
          Option,
          ValueContainer,
          DropdownIndicator,
        }
      : { DropdownIndicator };

    return (
      <Select
        options={options}
        styles={{
          menu: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          ...customStyles,
        }}
        isMulti={isMulti}
        isClearable={true}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        components={customComponents}
        onChange={onChange}
        value={value}
        ref={ref}
        {...other}
      />
    );
  }
);

export default CustomSelect;
