import { Link } from "react-router-dom";
import ContentfulRichText from "./contentful-rich-text";
import { useNavigazione } from "../context/navigazione";
import classNames from "classnames";
import { useMemo } from "react";
import { filterPageByMode } from "../utils/utility";

const News = ({ data }) => {
  const { getLink, mode } = useNavigazione();
  const article = useMemo(() => {
    return filterPageByMode(data.articoliCollection?.items, mode);
  }, [data, mode]);
  return (
    <section
      className={classNames(
        "news mb-3 py-3 py-md-5",
        data?.sfondoGrigio && "bg-gray"
      )}
    >
      <div className={data?.sfondoGrigio ? "container-md" : ""}>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="graphic">
              <span
                className={classNames(
                  "graphic__label",
                  data?.sfondoGrigio && "graphic__label--evidenza"
                )}
              >
                {data.titoloSezione || "In evidenza"}
              </span>
              <span className="graphic__line">&nbsp;</span>
            </h2>
          </div>
        </div>
        <div className="row">
          {article?.filter(Boolean).map((item, index) => {
            const link = getLink(item);
            return (
              <div className="col-sm-6 col-md-4" key={index}>
                <article className="mb-5">
                  <div className="news__img-cat">
                    <Link
                      className="news__link"
                      title={item?.titoloInPagina || item.titolo}
                      to={link}
                    >
                      <img
                        className="news__img"
                        alt=""
                        src={item?.immagineCard?.url}
                        width="100%"
                      />
                    </Link>
                    {item?.etichetta && (
                      <span className="news__category">{item.etichetta}</span>
                    )}
                  </div>
                  <h2 className="news__title">
                    <Link
                      className="news__link"
                      title="Cinque attività didattiche per scuole e famiglie"
                      to={link}
                    >
                      {item?.titoloInPagina || item.titolo}
                    </Link>
                  </h2>
                  {item.sommario && (
                    <ContentfulRichText
                      className="news__subject"
                      data={item.sommario}
                    />
                  )}
                </article>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default News;
