import classNames from "classnames";
import { useFasciaDidattica } from "../hooks/useQueryCustom";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
const FasciaDidattica = ({ id }) => {
  const { data } = useFasciaDidattica(id);
  const schede = data?.schedeCollection?.items;
  // console.log("FasciaArticoli: ", data);
  return (
    <section
      className={classNames(
        "news mb-3 py-3 py-md-5",
        data?.sfondoGrigio && "bg-gray"
      )}
    >
      <div className={data?.sfondoGrigio ? "container-md" : ""}>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="graphic">
              <span
                className={classNames(
                  "graphic__label",
                  data?.sfondoGrigio && "graphic__label--evidenza"
                )}
              >
                {data?.titolo || "Attività didattiche"}
              </span>
              <span className="graphic__line">&nbsp;</span>
            </h2>
          </div>
        </div>
        <div className="row">
          {schede?.map((item, index) => {
            return (
              <div key={index} className="col-sm-6 col-lg-4 my-4">
                <article className="edition d-flex flex-column h-100">
                  <Link
                    className="news__link"
                    title={item.titolo}
                    to={`/news/${item.link.slug}`}
                  >
                    <img
                      className="news__img"
                      alt=""
                      src={item?.immagine?.url}
                      width="100%"
                    />
                  </Link>

                  <h2 className="news__title">
                    <Link
                      className="news__link"
                      title="Cinque attività didattiche per scuole e famiglie"
                      to={`/news/${item.link.slug}`}
                    >
                      {item.titolo}
                    </Link>
                  </h2>
                  {item.descrizione && (
                    <ReactMarkdown className="edition__text flex-grow-1">
                      {item.descrizione}
                    </ReactMarkdown>
                  )}
                  <div className="edition__num w-100  d-flex flex-row">
                    <div className="d-flex flex-fill flex-column text-center">
                      <span className="edition__num-value">
                        {item.eta || "N.D."}
                      </span>
                      <span className="edition__num-label">Età</span>
                    </div>
                    <div className="d-flex flex-fill flex-column text-center">
                      <span className="edition__num-value">
                        {item.difficolta}
                      </span>
                      <span className="edition__num-label">Difficoltà</span>
                    </div>
                    <div className="d-flex flex-fill flex-column text-center">
                      <span className="edition__num-value">{item.durata}</span>
                      <span className="edition__num-label">Durata</span>
                    </div>
                    <div className="d-flex flex-fill flex-column text-center">
                      <span className="edition__num-value">
                        {item.disciplina}
                      </span>
                      <span className="edition__num-label">Disciplina</span>
                    </div>
                  </div>
                </article>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FasciaDidattica;
