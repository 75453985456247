import { useForm } from "react-hook-form";
import { Auth } from "@aws-amplify/auth";
import { getErrorMessage } from "../utils/cognito-errors";

const ForgotPassword = ({ email: formEmail }) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
    defaultValues: { email: formEmail, email2: "" },
  });

  const onSubmit = async (values) => {
    if (!values.email) {
      console.warn("Email necessaria");
      return;
    }
    try {
      const data = await Auth.forgotPassword(values.email, {
        from: "www.festivalscienza.it",
      });
      console.log("forgotPassword: ", data);
      clearErrors("server");
    } catch (err) {
      console.error("Error in forgotPassword: ", err);
      setError("server", { message: getErrorMessage(err) });
    }
  };

  return (
    <div className="login-box">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          {isSubmitSuccessful ? (
            <div>
              Ti abbiamo inviato una email con il link per cambiare la password.
              <br />
              Se non la trovi, controlla nello spam.
            </div>
          ) : (
            <form
              className="form"
              name="forgot-password"
              onSubmit={(e) => {
                clearErrors("server");
                handleSubmit(onSubmit)(e);
              }}
            >
              <label className="form__label">
                E-mail
                <input
                  type="email"
                  className="form__input me-2 p-1"
                  {...register("email", {
                    required: "Campo obbligatorio",
                  })}
                />
              </label>
              {errors.email && <span>{errors.email.message}</span>}
              <label className="form__label">
                Ripeti E-mail
                <input
                  type="email"
                  className="form__input me-2 p-1"
                  {...register("email2", {
                    required: "Campo obbligatorio",
                    validate: (value, values) =>
                      value === values.email || "Le email non coincidono",
                  })}
                />
              </label>
              {errors.email2 && (
                <span className="form__error">{errors.email2.message}</span>
              )}
              <div className="mt-4 text-center">
                <button
                  className="btn btn--link btn--small"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className="d-flex align-items-center">
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Caricamento...
                    </div>
                  ) : (
                    "Invia"
                  )}
                </button>
              </div>
              {errors.server && <span>{errors.server.message}</span>}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
