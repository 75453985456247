import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import Header from "../components/header";
import Breadcrumbs from "../components/breadcrumbs";
import EventCard from "../components/event-card";
import CustomSelect from "../components/custom-select";
import Icon from "../components/icons";
import CalendarSearch from "../components/calendar-search";
import { placeholders } from "../utils/admin";
import bennerOrientamento from "../assets/banner-orientamenti-2022.png";
import logoSanPaolo from "../assets/logo-fcsanpaolo.jpg";
import Seo from "../components/seo";

// import scaricaProgramma from "../assets/scarica-programma.jpg";
import {
  FESTIVAL_DAY_SCUOLE,
  DISCIPLINE,
  TIPO_EVENTO,
  target,
} from "../utils/constants";
import { usePage, useRelatori, useSearch } from "../hooks/useQueryCustom";
import PageContent from "../components/page-content";

const ProgrammaScuole = ({ page, breadcrumbs }) => {
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const resultSection = useRef();
  const emptyValue = {
    d_r: "",
    dt_r: "",
    g_r: "",
    gratis_r: false,
    idrelatore_r: "",
    ml_r: "",
    t_r: "",
    tgr_r: [],
  };
  const { data } = usePage(page.link.slice(1));
  const { data: relatori } = useRelatori();
  const { data: results } = useSearch(searchParams, "2");

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    // formState : { errors, isValid, isSubmitted, isSubmitSuccessful },
  } = useForm({
    defaultValues: async () => {
      let defaults = Object.fromEntries(searchParams);
      defaults.tgr_r = defaults.tgr_r?.split(",") || [];
      return { ...emptyValue, ...defaults };
    },
  });
  const watchDay = watch("dt_r");
  const currentPage = searchParams.get("from");

  const gotoPage = (page) => {
    console.log("gotoPage", page);
    const params = new URLSearchParams(searchParams);
    if (page > 0) {
      params.set("from", page * 12);
    } else {
      params.delete("from");
    }
    setSearchParams(params);
  };

  useEffect(() => {
    currentPage && resultSection.current.scrollIntoView();
  }, [currentPage]);

  useEffect(() => {
    if (
      searchParams.get("idrelatore_r") ||
      searchParams.get("d_r") ||
      searchParams.get("t_r")
    ) {
      setAdvanceSearch(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (watchDay) {
      const params = new URLSearchParams(searchParams);
      params.delete("from");
      params.set("dt_r", watchDay);
      setSearchParams(params);
    }
  }, [searchParams, setSearchParams, watchDay]);

  const onSubmit = (variables) => {
    const params = new URLSearchParams();
    for (const key in variables) {
      variables[key] && params.set(key, variables[key]);
    }
    params.delete("from");
    setSearchParams(params);
  };

  const getMultiValue = (value, options) => {
    const values = value?.map((item) => options.find((i) => i.value === item));
    return values;
  };

  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper  mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="programma-scuola__logo d-flex flex-column flex-md-row  justify-content-between align-items-center">
                <h1>Eventi online per le scuole</h1>
                <div>
                  <div className="programma-scuola__partner d-flex align-items-center justify-content-end mb-3">
                    <strong>In collaborazione con: </strong>
                    <img
                      className="ms-1"
                      height={70}
                      alt="Orientamenti 2022"
                      src={bennerOrientamento}
                    />
                  </div>
                  <div className="programma-scuola__partner d-flex align-items-center justify-content-end mb-3">
                    <strong>Con il sostegno di: </strong>
                    <img
                      className="ms-2"
                      height={70}
                      alt="Fondazione Compagnia di San Paolo"
                      src={logoSanPaolo}
                    />
                  </div>
                </div>
              </div>
              <PageContent data={data} />
            </div>
          </div>
        </div>
        <form name="search-event-scuole" onSubmit={handleSubmit(onSubmit)}>
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <Controller
                  control={control}
                  name="dt_r"
                  render={({ field: { onChange, value } }) => (
                    <CalendarSearch
                      data={FESTIVAL_DAY_SCUOLE}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="search-event py-4 mt-4">
            <div className="container-md">
              <fieldset>
                <div className="row">
                  <div className="col-12 col-xl-10 offset-xl-1">
                    <div className="d-flex flex-column flex-lg-row justify-content-between gap-4">
                      <div className="d-flex flex-column flex-grow-1">
                        <div className="d-flex flex-column flex-lg-row justify-content-between gap-4">
                          <input {...register("dt_r")} type="hidden" />
                          <Controller
                            name="g_r"
                            control={control}
                            render={({
                              field: { onChange, value, ...other },
                            }) => (
                              <CustomSelect
                                placeholder="Tipo Evento"
                                className="search-event__select"
                                options={TIPO_EVENTO}
                                value={
                                  TIPO_EVENTO.find(
                                    (type) => type.value === value
                                  ) || ""
                                }
                                onChange={(e) => {
                                  onChange(e?.value || "");
                                }}
                                isMulti={false}
                                {...other}
                              />
                            )}
                          />
                          <Controller
                            name="tgr_r"
                            control={control}
                            render={({
                              field: { onChange, value, ...other },
                            }) => (
                              <CustomSelect
                                placeholder="Età consigliata"
                                className="search-event__select"
                                options={target}
                                onChange={(e) => {
                                  onChange(
                                    Array.isArray(e)
                                      ? e.map((x) => x.value)
                                      : []
                                  );
                                }}
                                value={getMultiValue(value, target)}
                                isMulti={true}
                                {...other}
                              />
                            )}
                          />
                        </div>

                        <div
                          className={classNames(
                            "search-event__advanced",
                            advanceSearch && "search-event__advanced--open"
                          )}
                        >
                          <div className="d-flex flex-column justify-content-between gap-2">
                            <div className="w-100 mt-4">
                              <input
                                className="search-event__input"
                                placeholder="Cerca nel titolo"
                                {...register("t_r", { required: false })}
                                type="text"
                              />
                            </div>
                            <div className="mt-4 d-flex flex-column flex-lg-row gap-4">
                              <Controller
                                name="d_r"
                                control={control}
                                render={({
                                  field: { onChange, value, ...other },
                                }) => (
                                  <CustomSelect
                                    placeholder="Disciplina"
                                    className="search-event__select"
                                    options={DISCIPLINE}
                                    onChange={(e) => {
                                      onChange(e?.value || "");
                                    }}
                                    value={
                                      DISCIPLINE.find(
                                        (type) => type.value === value
                                      ) || ""
                                    }
                                    isMulti={false}
                                    {...other}
                                  />
                                )}
                              />
                              {relatori && (
                                <Controller
                                  name="idrelatore_r"
                                  control={control}
                                  render={({
                                    field: { onChange, value, ...other },
                                  }) => (
                                    <CustomSelect
                                      placeholder="Protagonisti"
                                      className="search-event__select"
                                      options={relatori}
                                      onChange={(e) => {
                                        onChange(e?.idrelatore || "");
                                      }}
                                      isMulti={false}
                                      getOptionLabel={(option) =>
                                        `${option.nome} ${option.cognome}`
                                      }
                                      value={
                                        relatori.find(
                                          (type) => type.idrelatore === value
                                        ) || ""
                                      }
                                      getOptionValue={(option) =>
                                        option.idrelatore
                                      }
                                      {...other}
                                    />
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="w-100 text-end mt-4">
                          <button
                            className="search-event__btn me-4"
                            title="Azzera filtri"
                            type="reset"
                            onClick={() => {
                              reset(emptyValue);
                            }}
                          >
                            Azzera filtri
                            <Icon className="ms-2" name="xmark"></Icon>
                          </button>
                          <button
                            type="button"
                            className="search-event__btn"
                            title="Ricerca avanzata"
                            onClick={() => setAdvanceSearch(!advanceSearch)}
                          >
                            Ricerca avanzata
                            <Icon
                              className="ms-2"
                              name={advanceSearch ? "angle-up" : "angle-down"}
                            ></Icon>
                          </button>
                        </div>
                      </div>
                      <div>
                        <button className="search-event__submit" type="submit">
                          Cerca
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
        <div ref={resultSection} />
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="search-event__result-number my-4">
                {results?.nrows} risultati{" "}
                {results?.current > 1 && ` - pagina ${results.current}`}
              </div>
              <div className="row">
                {(results?.rows || placeholders(6)).map((item, index) => (
                  <div key={index} className="col-sm-6 col-lg-4">
                    <EventCard data={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="search-event__pagination d-flex flex-wrap gap-2 justify-content-md-end">
                {results?.npages?.map((item, index) => (
                  <button
                    className={classNames(
                      "",
                      results.current === index && "active"
                    )}
                    key={index}
                    onClick={() => gotoPage(index)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProgrammaScuole;
