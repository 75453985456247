import { useState, useEffect } from "react";
import Icon from "../components/icons";

const Accordion = ({ title, children, initialValue = false }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setOpen(initialValue);
    }
  }, [initialValue]);

  return (
    <div className="accordion">
      <h2 className="accordion__title">
        <button
          className="accordion__link"
          title={`Apri paragrafo ${title}`}
          onClick={() => setOpen(!open)}
        >
          <span className="accordion__label">{title}</span>
          <span className="accordion__line">&nbsp;</span>
          <Icon name={open ? "angle-up" : "angle-down"} />
        </button>
      </h2>
      {open && <div className="accordion__text">{children}</div>}
    </div>
  );
};

export default Accordion;
